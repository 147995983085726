import { Button, Textarea, TextInput } from "flowbite-react";
import { FC } from "react";
import { CodeLabel } from "../../../components/CodeLabel";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { Groupbox } from "../../../components/Groupbox";
import { MaskedInput } from "../../../components/MaskedInput";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { useClienteColumns } from "../../../models/cliente/useClienteColumns";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { useTabDados } from "./TabDados.hook";
import { getCotacaoStatus, getCotacaoStatusColor } from "../../../models/cotacao/CotacaoModel";
import { Badge } from "../../../components/Badge";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import CodeUtil from "../../../utils/CodeUtil";
import { FormStateType } from "../../../enums";

export const TabDados: FC<ModalFormTabProps<ViewCotacaoCredenciadaModel>> = (props) => {
    const { 
        model, setModel, readonly, handleDowloadNFe, handleImprimir, onSearchCliente, onSearchVeiculo
     } = useTabDados(props);

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-6 md:col-span-3 mr-6">
                <CodeLabel value="Data:" htmlFor="inputData" className="mb-1" />
                <TextInput
                    id="inputData"
                    type={"date"}
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={model.dataCadastro}
                    readOnly={true} />
            </div>

            <div className="col-span-6 md:col-span-3 mr-6">
                <CodeLabel value="Validade:" htmlFor="inputValidade" className="mb-1" />
                <TextInput
                    id="inputValidade"
                    type={"date"}
                    disabled={true}
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={model.dataValidade}
                    onChange={(e) => setModel({ ...model, dataValidade: e.currentTarget.value })} />
            </div>

            <div className="col-span-6 md:col-span-3 mt-3 md:mt-0">
                <CodeLabel value="Situação:" htmlFor="selectSituacao" className="mb-1" />
                <div className="py-2.5 flex items-center align-middle">
                    <Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} />
                </div>
            </div>

            <div className="col-span-6 md:col-span-3 mt-3 md:mt-0 flex items-center ml-auto">
                {!CodeUtil.isNullOrEmpty(model.notaFiscalUrl) && <div className={`flex align-middle`}>
                    <div className={`ml-auto mr-1.5`}>
                        <Button size={"sm"} color={"light"} onClick={handleDowloadNFe}>Baixar NF-e <i className="ml-1.5 pi pi-cloud-download text-sm" /></Button>
                    </div>
                </div>}

                {props.state !== FormStateType.add && <div className={`flex align-middle`}>
                    <div className={`ml-auto`}>
                        <Button size={"sm"} color={"light"} onClick={handleImprimir}>Imprimir <i className="ml-1.5 pi pi-print text-sm" /></Button>
                    </div>
                </div>}
            </div>

            <Groupbox label="Informações do Cliente" id="groupBoxCliente" className="col-span-12 mt-6">
                <div className="form-control mt-3 col-span-12 md:col-span-6 md:mr-6">
                    <CodePesquisa
                        id="cpsCliente"
                        valueField={{ label: "Código:", property: "id", value: model.cliente?.id === 0 ? "" : model.cliente?.id }}
                        textField={{ label: "Razão Social:", property: "razaoSocial", value: model.cliente?.razaoSocial }}
                        modal={{
                            title: "Pesquisa de Clientes",
                            dataColumns: useClienteColumns(),
                            sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                        }}
                        readOnly={props.state !== FormStateType.add}
                        endpoint="cliente"
                        onSearch={onSearchCliente} />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteFantasia" value="Fantasia:" />
                    <TextInput
                        id="inputClienteFantasia"
                        type="text"
                        readOnly={true}
                        value={model.cliente?.fantasia ?? ""}
                        maxLength={150}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-3 mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteCnpj" value="CNPJ:" />
                    <MaskedInput
                        id="inputClienteCnpj"
                        mask="##.###.###/####-##"
                        value={model.cliente?.cnpj}
                        readOnly={true}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-3 mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteIE" value="Inscrição Estadual:" />
                    <TextInput
                        id="inputClienteIE"
                        value={model.cliente?.inscricaoEstadual ?? ""}
                        readOnly={true}
                    />
                </div>
            </Groupbox>

            <Groupbox label="Informações do Veículo" id="groupBoxVeiculo" className="col-span-12 mt-6">
                <div className="form-control mt-3 col-span-12 md:col-span-6 mr-6">
                    <CodePesquisa
                        id="cpsVeiculo"
                        valueField={{ label: "Código:", property: "id", value: model.veiculo?.id === 0 ? "" : model.veiculo?.id }}
                        textField={{ label: "Modelo:", property: "modeloMarca", value: model.veiculo?.modeloMarca ?? "" }}
                        modal={{
                            title: "Pesquisa de Veículo",
                            dataColumns: useClienteVeiculoColumns(),
                            sort: { columnProperty: "placa", direction: "asc", columnIndex: 3 }
                        }}
                        endpoint={`cliente/${model.cliente?.id ?? 0}/veiculo`}
                        readOnly={props.state !== FormStateType.add}
                        onSearch={onSearchVeiculo}
                        />
                </div>

                <div className="form-control col-span-6 mr-6 md:col-span-3 mt-3">
                    <CodeLabel value="Renavam:" htmlFor="inputRenavam" className="mb-1" />
                    <TextInput id="inputRenavam" value={model.veiculo?.renavam ?? ""}
                        readOnly={true} />
                </div>

                <div className="form-control col-span-6 md:col-span-3 mt-3">
                    <CodeLabel value="Placa:" htmlFor="inputPlaca" className="mb-1" />
                    <TextInput id="inputPlaca" value={model.veiculo?.placa ?? ""}
                        readOnly={true} />
                </div>
            </Groupbox>

            <Groupbox label="Observações" key={"groupBoxObservacoes"} className="col-span-12 mt-6">
                <div className="col-span-12 md:col-span-6 mt-3 md:mr-6">
                    <CodeLabel value="Observações do Cliente:" htmlFor="inputObservacoesCliente" className="mb-1" />
                    <Textarea
                        id="inputObservacoesCliente"
                        rows={5}
                        readOnly={true}
                        value={model.observacoesCliente ?? ""} />
                </div>

                <div className="col-span-12 md:col-span-6 mt-3">
                    <CodeLabel value="Observações:" htmlFor="inputObservacoes" className="mb-1" />
                    <Textarea
                        id="inputObservacoes"
                        rows={5}
                        readOnly={readonly}
                        value={model.observacoes ?? ""}
                        onChange={(e) => setModel({ ...model, observacoes: e.currentTarget.value })} />
                </div>
            </Groupbox>
        </div >
    );
};