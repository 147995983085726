import { IFiltroRelatorioCotacoes, IFiltroRelatorioOrdensServico, IFiltroRelatorioRepasses } from "../pages/relatorios/types";
import { BaseService } from "./BaseService";

const ENDPOINT = `${BaseService.BASE_URL}/relatorio`;

export class RelatorioService extends BaseService {

    public static async gerarRelatorioRepassesAsync(props: IFiltroRelatorioRepasses): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/repasses-servicos`, {
            DataCadastroIni: props.dataInicial,
            DataCadastroFim: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            Status: props.status
        });
    }

    public static async gerarRelatorioCotacoesAsync(props: IFiltroRelatorioCotacoes): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes`, {
            DataCadastroIni: props.dataInicial,
            DataCadastroFim: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            ClienteId: props.clienteId,
            VeiculoId: props.veiculoId,
            Status: props.status
        });
    }

    public static async gerarRelatorioOrdensServicoAsync(props: IFiltroRelatorioOrdensServico): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/ordens-servico`, {
            DataCadastroIni: props.dataInicial,
            DataCadastroFim: props.dataFinal,
            CredenciadaId: props.credenciadaId,
            ClienteId: props.clienteId,
            VeiculoId: props.veiculoId,
            CondutorId: props.condutorId,
            Status: props.status
        });
    }

    public static async gerarImpressaoCotacao(cotacaoId: number): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes/${cotacaoId}/imprimir`, null);
    }

    public static async gerarImpressaoCotacaoCredenciada(cotacaoId: number, credenciadaId: number | null): Promise<Blob | null> {
        return await this.REQUESTREPORT(`${ENDPOINT}/cotacoes/${cotacaoId}/credenciada/${credenciadaId}/imprimir`, null);
    }
}