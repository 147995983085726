import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useRouteGuard from "../hooks/RouteGuardHook";
import { NotFoundPage } from "./404";
import { MainLayout } from "./_layouts/MainLayout";
import { MainPage } from "./main";
import { GlobalStyle } from "./_styles/GlobalStyle";
import UsuariosPage from "./usuarios";
import { CredenciadasPage } from "./credenciadas";
import { ServicosPage } from "./servicos";
import { VeiculosMarcasPage } from "./veiculos_marcas";
import { ClientesPage } from "./clientes";
import { useSessionContext } from "../contexts/SessionContext";
import { UserType } from "../enums";
import { CotacoesPage } from "./cotacoes";
import { CotacoesCredenciadasPage } from "./cotacoes_credenciadas";
import { ConfiguracoesPage } from "./configuracoes";
import { RelatorioCotacoesPage } from "./relatorios/RelatorioCotacoesPage";
import { ClientesFrotasPage } from "./clientes_frotas";
import { CatalogosPage } from "./catalogos";
import {
  VeloeAbastecimentoPage, VeloeTransacoesPage, VeloeVeiculosPage, VeloeCartoesPage,
  VeloeSaldoContaPage, VeloeRecargaPage,
  VeloeRotasPage
} from "./veloe";
import { SnackbarProvider } from "notistack";
import { ForgotPasswordPage, LoginPage, InvalidTokenPage } from "./account";
import { ClientesCondutoresPage } from "./clientes_condutores";
import { RelatorioRepassesPage } from "./relatorios/RelatorioRepassesPage";
import { RelatorioOrdensServicoPage } from "./relatorios/RelatorioOrdensServicoPage";

export const App: React.FC = () => {

  const currentSession = useSessionContext();
  const userSession = currentSession.session?.user;

  return (
    <>
      <GlobalStyle />
      <SnackbarProvider />

      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/redefinir-senha" element={<ForgotPasswordPage />} />
        <Route path="/redefinir-senha/token-invalido" element={<InvalidTokenPage />} />

        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={useRouteGuard(<MainLayout children={<MainPage />} />)} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/usuarios" element={useRouteGuard(<MainLayout children={<UsuariosPage />} />)} />
        <Route path="/cotacoes" element={useRouteGuard(<MainLayout children={<CotacoesPage />} />, userSession?.tipoUsuario !== UserType.dealer)} />

        {/*Admin Routes */}
        <Route path="/clientes" element={useRouteGuard(<MainLayout children={<ClientesPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/credenciadas/empresas" element={useRouteGuard(<MainLayout children={<CredenciadasPage />} />, userSession?.tipoUsuario !== UserType.dealer)} />
        <Route path="/credenciadas/servicos" element={useRouteGuard(<MainLayout children={<ServicosPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/marcas-e-modelos" element={useRouteGuard(<MainLayout children={<VeiculosMarcasPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/catalogos" element={useRouteGuard(<MainLayout children={<CatalogosPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/configuracoes" element={useRouteGuard(<MainLayout children={<ConfiguracoesPage />} />, userSession?.tipoUsuario === UserType.admin)} />

        {/*Credenciadas Routes */}
        <Route path="/credenciadas/cotacoes" element={useRouteGuard(<MainLayout children={<CotacoesCredenciadasPage />} />, userSession?.tipoUsuario === UserType.dealer)} />

        {/*Clientes Routes*/}
        <Route path="/clientes/veiculos" element={useRouteGuard(<MainLayout children={<ClientesFrotasPage />} />, userSession?.tipoUsuario === UserType.customer)} />
        <Route path="/clientes/condutores" element={useRouteGuard(<MainLayout children={<ClientesCondutoresPage />} />, userSession?.tipoUsuario === UserType.customer)} />

        {/*Relatórios Routes */}
        <Route path="/relatorios/repasses" element={useRouteGuard(<MainLayout children={<RelatorioRepassesPage />} />, userSession?.tipoUsuario !== UserType.customer)} />
        <Route path="/relatorios/cotacoes" element={useRouteGuard(<MainLayout children={<RelatorioCotacoesPage />} />)} />
        <Route path="/relatorios/ordens_servico" element={useRouteGuard(<MainLayout children={<RelatorioOrdensServicoPage />} />)} />

        {/* Veloe */}
        <Route path="/veloe/veiculos" element={useRouteGuard(<MainLayout children={<VeloeVeiculosPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/historico_abastecimento" element={useRouteGuard(<MainLayout children={<VeloeAbastecimentoPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/historico_transacoes" element={useRouteGuard(<MainLayout children={<VeloeTransacoesPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/cartoes" element={useRouteGuard(<MainLayout children={<VeloeCartoesPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/saldo_conta" element={useRouteGuard(<MainLayout children={<VeloeSaldoContaPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/recarga_complementar" element={useRouteGuard(<MainLayout children={<VeloeRecargaPage />} />, userSession?.tipoUsuario === UserType.admin)} />
        <Route path="/veloe/rotas" element={useRouteGuard(<MainLayout children={<VeloeRotasPage />} />, userSession?.tipoUsuario === UserType.admin)} />
      </Routes>
    </>
  );
};
