import { useCallback, useEffect, useState } from "react";
import { getCotacaoStatus, getCotacaoStatusColor } from "../../models/cotacao/CotacaoModel";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";
import { CotacaoStatusType, FormStateType } from "../../enums";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { DefaultResponse } from "../../services/types";
import { Badge } from "../../components/Badge";
import { SessionManager } from "../../utils/SessionManager";
import { ViewCotacaoCredenciadaModel } from "../../models/cotacao/ViewCotacaoCredenciada";
import { CotacaoCredenciadaService } from "../../services/CotacaoCredenciadaService";
import { format } from "fecha";
import CodeUtil from "../../utils/CodeUtil";
import { IFiltroCotacao } from "../../types";

type ModalState = {
    id: number | null
    show: boolean,
    state: FormStateType
}

export const useCotacaoCredenciadaPage = () => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [dataSource, setDataSource] = useState<ViewCotacaoCredenciadaModel[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
    const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();
    const [modalState, setModalState] = useState<ModalState>({ show: false, state: FormStateType.view, id: null });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const credenciadaId = SessionManager.getSession().user?.credenciadaId;
    const [hiddenMoreFilters, setHiddenMoreFilters] = useState<boolean>(true);
    const [filtros, setFiltros] = useState<IFiltroCotacao>({
        dataInicial: format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD'),
        dataFinal: format(new Date(Date.now()), 'YYYY-MM-DD'),
        status: [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA]
    });

    const consultarCotacao = (readonly: boolean) => {
        if (!selectedRow || !selectedRow.item) {
            ShowToast({ message: "Nenhuma cotação selecionada." });
            return;
        }

        setModalState({ show: true, state: readonly ? FormStateType.view : FormStateType.edit, id: (selectedRow.item as ViewCotacaoCredenciadaModel).id });
    }

    const inserirCotacao = () => setModalState({ show: true, state: FormStateType.add, id: null });

    const cancelarCotacao = async () => {
        try {
            const model = selectedRow!.item as ViewCotacaoCredenciadaModel;
            const response = await CotacaoCredenciadaService.update(model.id!, credenciadaId!, { status: CotacaoStatusType.CANCELADA });
            if (!response.success) {
                console.log(response.messages);
                return;
            }

            await onFormLoad();
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setShowPopupDelete(false);
        }
    };

    const fetchCotacao = useCallback(async (filter?: IFiltroCotacao): Promise<DefaultResponse<ViewCotacaoCredenciadaModel>> => {
        const _filtros = filter ?? filtros;
        const defaultStatus = [CotacaoStatusType.AGUARDANDO, CotacaoStatusType.COTACAO_ENVIADA, CotacaoStatusType.REPROVADA, CotacaoStatusType.CANCELADA];

        return await CotacaoCredenciadaService.get(credenciadaId ?? 0, {
            page: page,
            limit: 100,
            dataCadastroIni: _filtros.dataInicial,
            dataCadastroFim: _filtros.dataFinal,
            status: _filtros.status?.length === 0 ? defaultStatus : _filtros.status
        });
    }, [credenciadaId, page, filtros]);

    const onFormLoad = useCallback(async (filter?: IFiltroCotacao) => {
        setIsLoadingData(true);
        fetchCotacao(filter).then((response) => {
            setDataSource(response.data ?? []);
            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                limit: response.pagination.limit,
                recordCount: response.pagination.recordCount
            });
        }).finally(() => setIsLoadingData(false));
    }, [setDataSource, setIsLoadingData, fetchCotacao, setPagination]);

    const onModalClose = useCallback(async () => {
        setModalState({ ...modalState, id: 0, show: false });
        await onFormLoad();
    }, [modalState, setModalState, onFormLoad]);

    const onFormatStatusColumn = (model: ViewCotacaoCredenciadaModel): JSX.Element => {
        return <span><Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} /></span>;
    }

    const onApplyFilter = async (value: IFiltroCotacao) => {
        await onFormLoad(value);
        setHiddenMoreFilters(true);
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        isLoadingData, dataSource, pagination, modalState, selectedRow,
        setPage, setSelectedRow, onModalClose,
        consultarCotacao, cancelarCotacao, inserirCotacao,
        onFormatStatusColumn, filtros, setFiltros, hiddenMoreFilters, setHiddenMoreFilters, onApplyFilter,
        showPopupDelete, setShowPopupDelete
    }
};