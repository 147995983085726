import { useState } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { FluentValidator } from "../../../types";
import { ViewCotacaoCredenciadaModel } from "../../../models/cotacao/ViewCotacaoCredenciada";
import { FormStateType } from "../../../enums";
import CodeUtil from "../../../utils/CodeUtil";
import { ClienteModel, defaultCliente } from "../../../models/cliente/ClienteModel";
import { ClienteVeiculoModel } from "../../../models/cliente_veiculo/ClienteVeiculoModel";
import { ShowToast } from "../../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import { RelatorioService } from "../../../services/RelatorioService";

export const useTabDados = (props: ModalFormTabProps<ViewCotacaoCredenciadaModel>) => {
    const [model, setModel] = props.model;
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const readonly = props.state === FormStateType.view;

    const handleDowloadNFe = () => {
        if (CodeUtil.isNullOrEmpty(model.notaFiscalUrl)) return;
        window.open(model.notaFiscalUrl!, "_blank");
    }

    const handleImprimir = async () => {
        try {
            ShowToast({ message: "Impressão solicitada! Enviaremos uma notificação quando a impressão estiver pronta." });
            const report = await RelatorioService.gerarImpressaoCotacaoCredenciada(model.id!, model.credenciadaId!);

            if (!report) {
                ShowToast({ message: UNKOWN_EXCEPTION });
                return;
            }
    
            CodeUtil.generateTempFileUrl(report);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }

    const onSearchCliente = (result: unknown): void => {
        try {
            if (!result) {
                setModel({ ...model, cliente: null });
                return;
            }

            setModel({
                ...model,
                cliente: {
                    ...defaultCliente,
                    ...(result as ClienteModel)
                },
                descontoPercentual: (result as ClienteModel).percentualDescontoContrato ?? 0,                
            });
        } catch (error) {
            setModel({ ...model, cliente: null });
        }
    };

    const onSearchVeiculo = (result: unknown): void => {
        try {
            if (!result) {
                setModel({ ...model, veiculo: null });
                return;
            }

            setModel({ ...model, veiculo: (result as ClienteVeiculoModel) });
        } catch (error) {
            setModel({ ...model, veiculo: null });
        }
    };

    return {
        model, setModel, errors, setErrors, readonly, 
        handleDowloadNFe, handleImprimir,
        onSearchCliente, onSearchVeiculo
    };
}