import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";

const useClienteVeiculoColumns = (): ICodeDataGridColumn[] => {
    return [
        { property: "modeloMarca", title: "Marca/Modelo" },
        { property: "anoFabricacao", title: "Ano Fabricação", alignment: 'center' },
        { property: "anoModelo", title: "Ano Modelo", alignment: 'center' },
        { property: "placa", title: "Placa" },
        { property: "renavam", title: "Renavam" },
    ]
}

export { useClienteVeiculoColumns }