import CodeUtil from "../../utils/CodeUtil";
import { BaseModel } from "../base";
import { ClienteCondutorModel } from "../cliente_condutor/ClienteCondutorModel";
import { ClienteContatoModel } from "../cliente_contato/ClienteContatoModel";
import { ClienteVeiculoModel } from "../cliente_veiculo/ClienteVeiculoModel";

export interface ClienteModel extends BaseModel {
    razaoSocial: string;
    fantasia: string | null;
    cnpj: string;
    inscricaoEstadual: string | null;
    logradouro: string | null;
    logradouroNumero: string | null;
    bairro: string | null;
    cep: string | null;
    observacoes: string | null;
    bloqueado: boolean;
    email: string | null;
    codigoVeloe?: string | null;
    percentualComissaoRodar?: number
    percentualDescontoContrato?: number
    municipio?: {
        id?: number;
        descricao?: string;
    };

    uf?: {
        id?: number;
        descricao?: string;
        sigla?: string;
    };

    permitirVisualizarCatalogos: boolean;

    contatos: ClienteContatoModel[];
    veiculos: ClienteVeiculoModel[];
    condutores: ClienteCondutorModel[];
};

export const defaultCliente: ClienteModel = {
    razaoSocial: '',
    fantasia: null,
    cnpj: '',
    inscricaoEstadual: null,
    logradouro: null,
    logradouroNumero: null,
    bairro: null,
    cep: null,
    bloqueado: false,
    observacoes: null,
    email: '',
    municipio: {
        descricao: '',
        id: 0
    },
    isSelected: false,
    permitirVisualizarCatalogos: true,
    contatos: [],
    veiculos: [],
    condutores: []
};

export const validateCliente = (model?: ClienteModel | null): string[] => {

    let errors: string[] = [];

    if (CodeUtil.isNullOrEmpty(model?.razaoSocial))
        errors.push("O campo 'Razão Social' deve ser preenchida.\r\n");

    if (CodeUtil.isNullOrEmpty(CodeUtil.removeSpecialChars(model?.cnpj)))
        errors.push("O campo 'CNPJ' deve ser preenchido.\r\n");

    if (!model?.municipio || !model.municipio.id)
        errors.push("O campo 'Município' deve ser preenchido.\r\n");

    return errors;
}