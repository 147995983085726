import { Button, TextInput } from "flowbite-react";
import { FC } from "react";
import { FiSearch } from "react-icons/fi";
import { ICodeDataGridHeader } from "../types";

export type CondensedCodeDataGridHeaderProps = {
    show: boolean
    onMoreFilter?: () => void
    readonly?: boolean
    onFilter?: (query: string) => void
} & ICodeDataGridHeader

export const CondensedCodeDataGridHeader: FC<CondensedCodeDataGridHeaderProps> = (props) => {
    if (!props.show) return <></>;

    return (
        <div className="bg-white border-b-2">

            <div className={"flex items-center pt-0 m-3"}>
                {!!!props.filter ? <></> :
                    <>
                        <div className={`${!props.onMoreFilter ? 'w-full' : 'w-full ml-auto'} md:mt-0 mt-3`}>
                            <TextInput onChange={(e) => props.onFilter?.call(this, e.currentTarget.value?.trim())} addon={<FiSearch />} sizing={"sm"} placeholder="Filtrar pesquisa" />
                        </div>

                        {!props.onMoreFilter ? <></> :
                            <div className="md:mt-0 mt-3 ml-3 min-w-max items-center">
                                <Button color={'light'} size={'sm'} onClick={props.onMoreFilter}>
                                    <span>Mais Filtros <i className="pi pi-filter text-sm" /></span>
                                </Button>
                            </div>}
                    </>}
            </div>
        </div>
    );
}