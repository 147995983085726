import { FC, useCallback, useEffect, useState } from "react";
import { ModalTabCredenciadasItemProps, useModalTabCredenciadasItem } from "./ModalTabCredenciadasItem.hook";
import ModalForm from "../../../components/ModalForm";
import CodeStaticList from "../../../components/CodeStaticList";
import { ModelState } from "../../../enums";
import CodeStaticListItem from "../../../components/CodeStaticList/Item";
import { StaticListItemCredenciadasItens } from "./StaticListItemCredenciadasItens";
import { CotacaoCredenciadaItemModel, getCotacaoCredenciadaItemDefault } from "../../../models/cotacao/CotacaoCredenciadaItemModel";

export const ModalTabCredenciadasItem: FC<ModalTabCredenciadasItemProps> = (props) => {
    const { handleButtonClose } = useModalTabCredenciadasItem(props);
    const credenciada = props.item;
    const [items, setItems] = useState<CotacaoCredenciadaItemModel[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const mountItems = useCallback(() => {

        let itensCotacao: CotacaoCredenciadaItemModel[] = [];
        props.cotacaoItens.filter(_ => _.state !== ModelState.deleted).forEach((_item) => {
            var itemCotacao = credenciada.itens?.find(_ => _.cotacaoItemId === _item.id ?? 0) ?? getCotacaoCredenciadaItemDefault();
            itensCotacao.push({ ...itemCotacao, ..._item });
        });

        setItems(itensCotacao);
    }, [setItems, credenciada.itens, props.cotacaoItens]);

    useEffect(() => {
        if (loaded) return;

        mountItems();
    }, [loaded, mountItems]);


    const handleFormClose = () => {
        setLoaded(false);
        handleButtonClose();
    };

    return (
        <ModalForm {...props}
            onClose={handleFormClose}

            title="Resumo da Cotação">
            <div className="flex flex-col">
                <div className="grid grid-cols-12 m-3">
                    <div className="col-span-12">
                        {items.length > 0 ?
                            <></> :
                            <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                                <span className="text-slate-500">Nenhum item cotado para esta credenciada</span>
                            </div>}


                        <CodeStaticList>
                            {items.map((_item, idx) =>
                                <CodeStaticListItem key={idx}>
                                    <StaticListItemCredenciadasItens model={_item} key={idx} state={props.state} index={idx} />
                                </CodeStaticListItem>)}
                        </CodeStaticList>
                    </div>
                </div>
            </div>
        </ModalForm>
    );
};