import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CodeUtil from '../../utils/CodeUtil';
import { CondensedCodeDataGridColumnHeader } from './components/CondensedCodeDataGridColumnHeader';
import { CondensedCodeDataGridTextColumn } from './components/CondensedCodeDataGridTextColumn';
import { CondensedCodeDataGridHeader } from './components/CondensedCodeDataGridHeader';
import { CondensedCodeDataGridFooter } from './components/CondensedCodeDataGridFooter';
import { ICodeDataGridColumn, ICodeDataGridHeader, ICodeDataGridPagination, ICodeDataGridRow, ICodeDataGridSort } from './types';
import { CondensedCodeDataGridRowItem } from './components/CondensedCodeDataGridRowItem';

export type CondensedCodeDataGridProps = {
    datasource?: any[]
    className?: string
    striped?: boolean | undefined
    hoverable?: boolean
    multiselect?: boolean | undefined
    selectAll?: boolean
    columns: ICodeDataGridColumn[]
    pagination?: ICodeDataGridPagination
    header?: ICodeDataGridHeader
    sort?: ICodeDataGridSort
    readonly?: boolean
    hiddenFooter?: boolean
    height?: string
    onRowSelect?: (item: ICodeDataGridRow) => void
    onRowsSelect?: (item: ICodeDataGridRow[]) => void
    onContextMenu?: MouseEventHandler | undefined;
    onMoreFilter?: () => void
}

export const onCustomFormatColumn = (item: any, property: string, type: "money" | "number" | "integer" | "percent" | "text" | "minutes" | "hours" = "text"): JSX.Element => {
    if (type === "money") {
        return <span>{CodeUtil.moneyFormat(CodeUtil.getPropertyValue(item, property) ?? 0, true)}</span>
    }

    if (type === "number") {
        return <span>{CodeUtil.moneyFormat(CodeUtil.getPropertyValue(item, property) ?? 0, false)}</span>
    }

    if (type === "integer") {
        return <span>{parseInt(CodeUtil.getPropertyValue(item, property) ?? "0")}</span>
    }

    if (type === "percent") {
        return <span>{CodeUtil.moneyFormat(CodeUtil.getPropertyValue(item, property) ?? 0, false)} %</span>
    }

    if (type === "minutes") {
        return <span>{CodeUtil.moneyFormat(CodeUtil.getPropertyValue(item, property) ?? 0, false)} min</span>
    }

    if (type === "hours") {
        return <span>{CodeUtil.moneyFormat(CodeUtil.getPropertyValue(item, property) ?? 0, false)} hrs</span>
    }

    return <span>{CodeUtil.getPropertyValue(item, property)}</span>
}


export const CondensedCodeDataGrid: FC<CondensedCodeDataGridProps> = (props) => {
    const [dataSource, setDataSource] = useState<any[] | undefined>(props.datasource);
    const [selectedRows, setSelectedRows] = useState<ICodeDataGridRow[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(props.selectAll ?? false);
    const [sort, setSort] = useState<ICodeDataGridSort>(props.sort ?? { columnIndex: -1, direction: 'asc' })
    const [query, setQuery] = useState<string>('');
    const tableHeader = useRef<HTMLDivElement>(null);
    const minColumnCount: number = 10;

    const handleSortColumn = async (property: string, index: number, direction: 'asc' | 'desc') => {
        const source = props.datasource ?? dataSource;

        setSort({ ...sort, columnIndex: index, columnProperty: property, direction: direction });

        const sortedData = source?.sort((a, b) => CodeUtil.isNumeric(a[property]) ?
            (direction === 'asc' ? String(a[property]).padStart(10, "0").localeCompare(String(b[property]).padStart(10, "0")) : String(b[property]).padStart(10, "0").localeCompare(String(a[property]).padStart(10, "0"))) :
            (direction === 'asc' ? String(a[property]).localeCompare(b[property]) : String(b[property]).localeCompare(a[property])));

        setDataSource(sortedData);
        renderRowData();
    };

    const filterDataSource = useCallback((): any[] | undefined => {
        const source = props.datasource ?? dataSource;

        const filteredDataSource = CodeUtil.isNullOrEmpty(query) ? props.datasource : source?.filter((value) => String(CodeUtil.getPropertyValue(value, sort.columnProperty ?? ''))
            .toLocaleLowerCase()
            .normalize('NFD').replace(/\p{Diacritic}/gu, "")
            .indexOf(query!.toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").trim()) >= 0);

        return filteredDataSource;
    }, [props.datasource, dataSource, query, sort.columnProperty]);

    const handleRowSelect = useCallback((item: ICodeDataGridRow) => {
        var selected = !props.multiselect ? [] : selectedRows.filter(x => x.rowindex !== item.rowindex && x.selected);
        
        if (item.selected) selected.push(item);
        setSelectedRows(selected);

        props.onRowSelect?.call(this, item);
        props.onRowsSelect?.call(this, selected);
    }, [selectedRows, props, setSelectedRows]);

    const handleSelectAllRows = (select: boolean) => {
        setSelectAll(select);

        if (!select) {
            setSelectedRows([]);
            props.onRowsSelect?.call(this, []);
            return;
        }

        const selected: ICodeDataGridRow[] = [];
        dataSource?.forEach((row, index) => selected.push({ item: row, rowindex: index, selected: true }));
        setSelectedRows(selected);

        props.onRowsSelect?.call(this, selected);
    }

    const renderRowData = useCallback(() => {
        let columns: ICodeDataGridColumn[] = props.columns;

        if (props.columns.length < minColumnCount) {
            let ghostCols = Array<ICodeDataGridColumn>(minColumnCount - props.columns.length).fill({ title: '' });
            columns.push(...ghostCols);
        }

        return (<tbody className={`${props.className ?? ''}`}>
            {filterDataSource()?.map((item, index) => <CondensedCodeDataGridRowItem
                key={index}
                columns={columns}
                striped={props.striped}
                hoverable={props.hoverable}
                readonly={props.readonly}
                item={item}
                selected={selectedRows?.find(x => x.rowindex === index) !== undefined}
                onRowSelect={(selected) => handleRowSelect({ item, rowindex: index, selected })} />)}
        </tbody>);
    }, [handleRowSelect, selectedRows, filterDataSource, props]);

    const renderGhostColumns = () => {
        let columns: ICodeDataGridColumn[] = [];
        if (props.columns.length < minColumnCount) {
            let ghostCols = Array<ICodeDataGridColumn>(minColumnCount - props.columns.length).fill({ title: '' });
            columns.push(...ghostCols);
        }
        return (columns.map((_col, index) => <th key={index} scope='col'>{' '}</th>));
    }

    useEffect(() => {
        renderRowData();
    }, [renderRowData]);

    return (
        <StyledCodeDataGrid className={"bg-slate-50/50 m-0 p-0"} about={props.height} onContextMenu={props.onContextMenu}>
            <CondensedCodeDataGridHeader {...props.header}
                show={props.header !== undefined}
                readonly={props.readonly}
                onMoreFilter={props.onMoreFilter}
                onFilter={(query) => setQuery(query)} />

            <div className='overflow-x-auto code-table' onScrollCapture={(e) => tableHeader?.current?.scrollTo({ left: e.currentTarget.scrollLeft })}>
                <table className={`${props.className ?? ''} w-full text-sm text-left text-gray-500`}>
                    <CondensedCodeDataGridColumnHeader 
                        readOnly={props.readonly}
                        multiselect={props.multiselect}
                        selectedAll={selectAll}
                        onSelectAll={handleSelectAllRows}>
                        <>
                            {props.columns.map((col, index) => <CondensedCodeDataGridTextColumn key={index}
                                title={col.title}
                                alignment={col.alignment}
                                sorted={sort.columnIndex === index}
                                onSort={async (direction) => handleSortColumn(col.property ?? "", index, direction)} />)}

                            {renderGhostColumns()}
                        </>
                    </CondensedCodeDataGridColumnHeader>

                    {renderRowData()}
                </table>
            </div>

            {
                props.hiddenFooter ? <></> :
                    <footer className='footer relative w-full'>
                        <CondensedCodeDataGridFooter pagination={props.pagination} />
                    </footer>
            }
        </StyledCodeDataGrid>
    );
};


const StyledCodeDataGrid = styled.div`
    width: 100%;
    min-height: 50vh;
    margin: 0;
    background-color: #fff;
    width: auto;

    .code-table {
        height: 50vh;
        width: 100%;
    }
`