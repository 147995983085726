import { FC } from "react";
import { ClienteFrotaCadastroFormProps } from "./types";
import { useClientesFrotasCadastroPage } from "./index.hook";
import ModalForm from "../../components/ModalForm";
import { CodeLabel } from "../../components/CodeLabel";
import { MaskedInput } from "../../components/MaskedInput";
import { Checkbox, Select, TextInput } from "flowbite-react";
import { findValidationField } from "../../types";

export const ClientesFrotasCadastroPage: FC<ClienteFrotaCadastroFormProps> = (props) => {
    const {
        onFormClose, onFormSave, fetchFormProps,
        model, setModel, readonly, errors,
        arrayMarcas, arrayModelos, onLoadModelos, arrayYears
    } = useClientesFrotasCadastroPage(props);


    return (
        <ModalForm
            title={'Cadastro de veículo'}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={fetchFormProps().saving}
            size="4xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            <div className="grid grid-cols-12 px-6 pb-3">
                <div className="form-control mt-5 col-span-12 md:col-span-6 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectMarca" value="Marca:" />
                    <Select
                        id="selectMarca"
                        disabled={readonly}
                        value={model?.marca?.id}
                        onChange={(e) => {
                            setModel({
                                ...model, marca:
                                {
                                    ...model.marca,
                                    id: Number(e.currentTarget.value),
                                    nome: arrayMarcas.find((m) => m.id === Number(e.currentTarget.value))?.marca ?? ''
                                }
                            });
                            onLoadModelos(Number(e.currentTarget.value))
                        }}
                        helperText={findValidationField(errors, "marca").message}
                        color={findValidationField(errors, "marca").isValid ? "gray" : "failure"}
                    >
                        <option value={0}>Selecione</option>
                        {arrayMarcas.map((marca) => (<option key={marca.id} value={marca.id}>{marca.marca}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="selectModelo" value="Modelo:" />
                    <Select
                        id="selectModelo"
                        disabled={readonly}
                        value={model?.modelo?.id}
                        helperText={findValidationField(errors, "modelo").message}
                        color={findValidationField(errors, "modelo").isValid ? "gray" : "failure"}
                        onChange={(e) => {
                            setModel({
                                ...model,
                                modelo:
                                {
                                    ...model.modelo,
                                    id: Number(e.currentTarget.value),
                                    nome: arrayModelos.find((m) => m.id === Number(e.currentTarget.value))?.modelo ?? ''
                                }
                            });
                        }}
                    >
                        <option value={0}>Selecione</option>
                        {arrayModelos.map((modelo) => (<option key={modelo.id} value={modelo.id}>{modelo.modelo}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectFabricacao" value="Fabricação:" />
                    <Select
                        id="selectFabricacao"
                        disabled={readonly}
                        value={model?.anoFabricacao}
                        helperText={findValidationField(errors, "anoFabricacao").message}
                        color={findValidationField(errors, "anoFabricacao").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, anoFabricacao: Number(e.currentTarget.value) })}
                    >
                        {arrayYears.map((year) => (<option key={year} value={year}>{year}</option>))}
                    </Select>
                </div>


                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="selectModelo" value="Modelo:" />
                    <Select
                        id="selectModelo"
                        disabled={readonly}
                        value={model?.anoModelo}
                        helperText={findValidationField(errors, "anoModelo").message}
                        color={findValidationField(errors, "anoModelo").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, anoModelo: Number(e.currentTarget.value) })}
                    >
                        {arrayYears.map((year) => (<option key={year} value={year}>{year}</option>))}
                    </Select>
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3 md:mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputPlaca" value="Placa:" />
                    <TextInput
                        id="inputPlaca"
                        type="text"
                        maxLength={7}
                        readOnly={readonly}
                        value={model?.placa}
                        helperText={findValidationField(errors, "placa").message}
                        color={findValidationField(errors, "placa").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, placa: e.currentTarget.value.toUpperCase() })}
                    />
                </div>

                <div className="form-control mt-5 col-span-6 md:col-span-3">
                    <CodeLabel className="mb-1" htmlFor="inputRenavam" value="Renavam:" />
                    <MaskedInput
                        id="inputRenavam"
                        type="text"
                        pattern="^[0-9]+$"
                        mask="###########"
                        maxLength={11}
                        readOnly={readonly}
                        value={model?.renavam}
                        helperText={findValidationField(errors, "renavam").message}
                        color={findValidationField(errors, "renavam").isValid ? "gray" : "failure"}
                        onChange={(e) => setModel({ ...model, renavam: e })}
                    />
                </div>

                <div className="form-control mt-6 col-span-12 flex space-x-2 items-center">
                    <Checkbox
                        id="checkBoxInativo"
                        disabled={readonly}
                        checked={model.inativo}
                        onChange={(e) => setModel({ ...model, inativo: e.currentTarget.checked })}
                        placeholder="Checked"
                    />
                    <CodeLabel htmlFor="checkBoxInativo" value="Veículo inativo" />
                </div>
            </div>
        </ModalForm>
    );
};