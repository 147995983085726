import { useState, useCallback, useEffect, MouseEvent, SetStateAction, Dispatch } from "react";
import { ModalFormProps } from "../../../components/ModalForm/types";
import { CotacaoTipoItemType, FormStateType, ModelState } from "../../../enums";
import { FluentValidator } from "../../../types";
import CodeUtil from "../../../utils/CodeUtil";
import { CotacaoItemModel } from "../../../models/cotacao/CotacaoItemModel";
import { ServicoModel } from "../../../models/servico";
import { ServicoService } from "../../../services/ServicoService";

export type ModalTabItensLancamentoProps = {
    item: [CotacaoItemModel, Dispatch<SetStateAction<CotacaoItemModel>>]
} & ModalFormProps

export const useModalTabItensLancamento = (props: ModalTabItensLancamentoProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [item, setItem] = props.item;
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [dataServicos, setDataServicos] = useState<ServicoModel[]>([]);

    const fetchServicos = useCallback(async () => {
        const response = await ServicoService.get({
            limit: 999999,
            page: 1,
            ativo: props.state === FormStateType.view ? null : true
        });

        if (!response.success)
            return setDataServicos([]);

        const servicos = response.data as ServicoModel[];
        return setDataServicos(servicos.sort((a, b) => b.nome > a.nome ? -1 : 1));
    }, [props.state]);

    const onFormLoad = useCallback(async () => {
        if (loaded) return;

        await fetchServicos();
        setLoaded(true);
    }, [loaded, setLoaded, fetchServicos]);


    const handleButtonClose = () => {
        setLoaded(false);
        props.onClose?.call(this);
    }

    const handleButtonSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!validate()) return;

        setLoaded(false);
        setItem({ ...item, state: props.state === FormStateType.add || !!!item.id ? ModelState.added : ModelState.updated });
        props.onSave?.call(this, e);
    }

    const validate = (): boolean => {
        let _errors: FluentValidator[] = [];
        setErrors([]);

        if (CodeUtil.isNullOrEmpty(item.codigoFornecedor) && item.tipo === CotacaoTipoItemType.ITEM) {
            _errors.push({ message: "O código do item deve ser preenchido.", field: "codigoFornecedor", isValid: false });
        }

        if (CodeUtil.isNullOrEmpty(item.descricao)) {
            _errors.push({ message: "A descrição da peça/serviço deve ser preenchida.", field: "descricao", isValid: false });
        }

        setErrors(_errors);
        return _errors.map(_ => !!_.isValid).length === 0;
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return {
        item, setItem,
        loaded, dataServicos,
        handleButtonClose, handleButtonSave,
        errors, setErrors
    };
};