import { FC, useCallback, useEffect, useRef, useState } from "react";
import ModalForm from "../../../components/ModalForm";
import { CotacaoStatusType, FormStateType } from "../../../enums";
import { format } from "fecha";
import { CodeLabel } from "../../../components/CodeLabel";
import { CodeSelectableChip } from "../../../components/CodeSelectableChip";
import { getCotacaoStatus } from "../../../models/cotacao/CotacaoModel";
import { TextInput } from "flowbite-react";
import { ModalFiltroOrdensServicoProps } from "../types";
import { ICodePesquisaValue } from "../../../components/CodePesquisa/types";
import { CredenciadaModel } from "../../../models/credenciada";
import { ClienteModel } from "../../../models/cliente/ClienteModel";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { useCredenciadaColumns } from "../../../models/credenciada/useCredenciadaColumns";
import { useClienteColumns } from "../../../models/cliente/useClienteColumns";
import CodeUtil from "../../../utils/CodeUtil";
import { SessionManager } from "../../../utils/SessionManager";
import { ClienteCondutorModel } from "../../../models/cliente_condutor/ClienteCondutorModel";
import { ClienteVeiculoModel } from "../../../models/cliente_veiculo/ClienteVeiculoModel";
import { useCondutorColumns } from "../../../models/cliente_condutor/useCondutorColumns";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";


export const FiltroRelatorioOrdensServico: FC<ModalFiltroOrdensServicoProps> = (props) => {
    const STATUS_ITEMS = [CotacaoStatusType.APROVADA, CotacaoStatusType.EMEXECUCAO, CotacaoStatusType.ENCERRADA];
    const inputDataInicial = useRef<HTMLInputElement>(null);
    const inputDataFinal = useRef<HTMLInputElement>(null);
    const [filter, setFilter] = props.model;
    const [status, setStatus] = useState<CotacaoStatusType[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [credenciadaPesquisa, setCredenciadaPesquisa] = useState<ICodePesquisaValue | null>(null);
    const [clientePesquisa, setClientePesquisa] = useState<ICodePesquisaValue | null>(null);
    const [veiculoPesquisa, setVeiculoPesquisa] = useState<ICodePesquisaValue | null>(null);
    const [condutorPesquisa, setCondutorPesquisa] = useState<ICodePesquisaValue | null>(null);
    const columnsCredenciada = useCredenciadaColumns();
    const columnsCliente = useClienteColumns();
    const columnsVeiculo = useClienteVeiculoColumns();
    const columnsCondutor = useCondutorColumns();
    const currentUser = SessionManager.getSession().user;

    const loadDefaultValues = useCallback(() => {
        if (inputDataInicial.current !== undefined && inputDataInicial.current !== null)
            inputDataInicial.current.value = filter.dataInicial ?? format(new Date(Date.now()), "YYYY-MM-DD");

        if (inputDataFinal.current !== undefined && inputDataFinal.current !== null)
            inputDataFinal.current.value = filter.dataFinal ?? format(new Date(Date.now()), "YYYY-MM-DD");

        setStatus(filter?.status ?? []);
    }, [filter, setStatus]);

    const onFormLoaded = useCallback(() => {
        if (loaded) return;

        loadDefaultValues();
        setLoaded(true);
    }, [loaded, loadDefaultValues]);

    const handleSelectStatus = (model: unknown) => {
        const value = model as CotacaoStatusType;
        if (status?.includes(value))
            setStatus(status.filter((element) => element !== value!) ?? []);
        else
            setStatus(prev => [...prev ?? [], value]);
    };

    const handleClose = () => {
        loadDefaultValues();
        props.onClose?.call(this);
    }

    const handleApplyFilter = () => {
        let filtered = {
            ...filter,
            dataInicial: inputDataInicial.current?.value,
            dataFinal: inputDataFinal.current?.value,
            status: status,
        };

        if (clientePesquisa?.value) {
            filtered.clienteId = clientePesquisa?.value
        }

        if (credenciadaPesquisa?.value) {
            filtered.credenciadaId = credenciadaPesquisa.value
        }

        setFilter(filtered);
        props.onApplyFilter?.call(this, filtered);
    }

    const handleSelecionarCredenciada = (result: unknown) => {
        if (!result) {
            setCredenciadaPesquisa(null);
            return;
        }

        setCredenciadaPesquisa({
            text: (result as CredenciadaModel).razaoSocial,
            value: (result as CredenciadaModel).id
        });
    }

    const handleSelecionarCliente = (result: unknown) => {
        if (!result) {
            setClientePesquisa(null);
            return;
        }

        setClientePesquisa({
            text: (result as ClienteModel).razaoSocial,
            value: (result as ClienteModel).id
        });
    }
    
    const handleSelecionarVeiculo = (result: unknown) => {
        if (!result) {
            setVeiculoPesquisa(null);
            return;
        }

        setVeiculoPesquisa({
            text: (result as ClienteVeiculoModel).modeloMarca,
            value: (result as ClienteVeiculoModel).id
        });
    }

    const handleSelecionarCondutor = (result: unknown) => {
        if (!result) {
            setCondutorPesquisa(null);
            return;
        }

        setCondutorPesquisa({
            text: (result as ClienteCondutorModel).nomeCompleto,
            value: (result as ClienteCondutorModel).id
        });
    }

    useEffect(() => {
        onFormLoaded();
    }, [onFormLoaded]);

    return (
        <ModalForm
            show={props.show}
            state={FormStateType.edit}
            size="3xl"
            errors={[]}
            title="Filtrar Relatório"
            onClose={handleClose}
            onSave={handleApplyFilter}
            saveButtonTitle={'Gerar'}
            isSaving={false}

        >
            <div className="grid grid-cols-12 gap-x-3 p-5">
                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Inicio:" className="mb-1" htmlFor="inputDataInicial" />
                    <TextInput type={"date"}
                        id="inputDataInicial"
                        ref={inputDataInicial}
                        defaultValue={format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD')} />
                </div>

                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Término:" className="mb-1" htmlFor="inputDataFinal" />
                    <TextInput type={"date"}
                        id="inputDataFinal"
                        ref={inputDataFinal}
                        defaultValue={format(new Date(Date.now()), 'YYYY-MM-DD')} />
                </div>

                {!currentUser?.credenciadaId &&
                    <div className="col-span-12 mt-5">
                        <CodePesquisa
                            id="cpsCredenciada"
                            valueField={{ label: "Código:", property: "id", value: credenciadaPesquisa?.value === 0 ? "" : credenciadaPesquisa?.value?.toString() }}
                            textField={{ label: "Nome da Credenciada:", property: "razaoSocial", value: credenciadaPesquisa?.text ?? "" }}
                            modal={{
                                title: "Pesquisa de Credenciadas",
                                dataColumns: columnsCredenciada,
                                sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                            }}
                            onSearch={handleSelecionarCredenciada}
                            endpoint="credenciada" />
                    </div>}

                {!currentUser?.clienteId &&
                    <div className="col-span-12 mt-3">
                        <CodePesquisa
                            id="cpsCliente"
                            valueField={{ label: "Código:", property: "id", value: clientePesquisa?.value === 0 ? "" : clientePesquisa?.value?.toString() }}
                            textField={{ label: "Nome do Cliente:", property: "razaoSocial", value: clientePesquisa?.text ?? "" }}
                            modal={{
                                title: "Pesquisa de Clientes",
                                dataColumns: columnsCliente,
                                sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                            }}
                            onSearch={handleSelecionarCliente}
                            endpoint="cliente" />
                    </div>}

                {(clientePesquisa?.value || currentUser?.clienteId) &&
                    <>
                        <div className="col-span-12 mt-5 space-y-5">
                            <CodePesquisa
                                id="cpsVeiculo"
                                valueField={{ label: "Código:", property: "id", value: veiculoPesquisa?.value === 0 ? "" : veiculoPesquisa?.value?.toString() }}
                                textField={{ label: "Marca/Modelo:", property: "modeloMarca", value: veiculoPesquisa?.text ?? "" }}
                                modal={{
                                    title: "Pesquisa de Veículos",
                                    dataColumns: columnsVeiculo,
                                    sort: { columnProperty: "marcaModelo", direction: "asc", columnIndex: 2 }
                                }}
                                onSearch={handleSelecionarVeiculo}
                                endpoint={`cliente/${clientePesquisa?.value ?? currentUser?.clienteId ?? 0}/veiculo`} />

                            <CodePesquisa
                                id="cpsCondutor"
                                valueField={{ label: "Código:", property: "id", value: condutorPesquisa?.value === 0 ? "" : condutorPesquisa?.value?.toString() }}
                                textField={{ label: "Nome do Condutor:", property: "nomeCompleto", value: condutorPesquisa?.text ?? "" }}
                                modal={{
                                    title: "Pesquisa de Condutores",
                                    dataColumns: columnsCondutor,
                                    sort: { columnProperty: "nome", direction: "asc", columnIndex: 2 }
                                }}
                                onSearch={handleSelecionarCondutor}
                                endpoint={`cliente/${clientePesquisa?.value ?? currentUser?.clienteId ?? 0}/condutor`} />
                        </div>
                    </>}

                <div className="col-span-12 mt-4 gap-x-3">
                    <CodeLabel value="Status:" className="mb-1" />
                    {STATUS_ITEMS.map((item) => <CodeSelectableChip
                        key={item}
                        model={item}
                        className="m-0.5 text-sm"
                        selected={status?.includes(item)}
                        onSelectChip={handleSelectStatus}
                        label={getCotacaoStatus(item)} />)}
                </div>
            </div>
        </ModalForm >
    );
};