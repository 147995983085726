import { FC } from "react";
import { CotacaoCredenciadaCadastroFormProps, useCotacaoCredenciadaCadastroPage } from "./index.hook";
import ModalForm from "../../components/ModalForm";
import { Tabs } from "flowbite-react";
import { TabDados } from "./components/TabDados";
import { TabItens } from "./components/TabItens";
import { FooterTotais } from "./components/FooterTotais";
import { TabRegistroEntradaSaida } from "./components/TabRegistroEntradaSaida";

export const CotacoesCredenciadasCadastroPage: FC<CotacaoCredenciadaCadastroFormProps> = (props) => {
    const { onFormClose, onFormSave, fetchFormProps, model, setModel, showHistoricoEntradaSaida } = useCotacaoCredenciadaCadastroPage(props);

    return (
        <ModalForm
            title={model.id ? `Cotação #${(model.id ?? 0).toString().padStart(5, "0")} - ${model.cliente?.razaoSocial}` : 'Lançamento de Cotação'}
            errors={[]}
            show={props.show}
            saveButtonTitle={model.id ? 'Enviar' : 'Gravar'}
            state={props.state}
            isSaving={fetchFormProps().saving}
            size="7xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            {/* eslint-disable-next-line */}
            <Tabs.Group aria-label="Abas" style="underline">

                <Tabs.Item title="Dados" active={true} key="cotacao">
                    {props.show && <TabDados
                        model={[model, setModel]}
                        show={props.show}
                        state={props.state}
                        id={props.id}
                        key="id"
                    />}
                </Tabs.Item>

                <Tabs.Item title="Peças e Serviços" key="itens">
                    {props.show && <TabItens
                        model={[model, setModel]}
                        show={props.show}
                        state={props.state}
                        id={props.id}
                        key="id"
                    />}
                </Tabs.Item>

                <Tabs.Item disabled={!showHistoricoEntradaSaida()} title="Histórico de Entrada/Saída" key="historico">
                    {props.show && model.id && <TabRegistroEntradaSaida
                        model={[model, setModel]}
                        show={props.show}
                        state={props.state}
                        id={props.id}
                        key="id"
                    />}
                </Tabs.Item>
            </Tabs.Group>

            <FooterTotais model={model} />
        </ModalForm>
    );
};