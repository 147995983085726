import { AxiosError } from "axios";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultUsuario, UsuarioModel, validateUsuario } from "../../../../models/usuario";
import { UsuarioService } from "../../../../services/UsuarioService";
import CodeUtil from "../../../../utils/CodeUtil";
import { KeyboardEvent } from "react";
import { SessionManager } from "../../../../utils/SessionManager";

export const UsuariosModal: FC<ModalFormProps> = (props) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<UsuarioModel>(defaultUsuario);
    const isReadOnly = props.state === FormStateType.view;
    const sessionUser = SessionManager.getSession().user;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        if (props.id !== null && props.id !== undefined && props.id > 0) {
            let response = await UsuarioService.getId(props.id);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else setModel(defaultUsuario);

        setFormLoaded(true);
    }, [props.id, props.show, formLoaded, setFormLoaded]);

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {

        let validationResult = validateUsuario(model);
        setErrors(validationResult);

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            
            let postModel = model;

            if (sessionUser?.clienteId) postModel.clienteId = sessionUser.clienteId;
            if (sessionUser?.credenciadaId) postModel.credenciadaId = sessionUser.credenciadaId;
            
            let response = props.state === FormStateType.add ? await UsuarioService.add(postModel) : await UsuarioService.update(postModel);

            if (!response.success) {
                setErrors(response.messages);
                return;
            }

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            setErrors([(error as AxiosError).response?.data as string])
        }
        finally {
            setSaving(false);
        }
    }

    const generateUserName = () => {
        if (props.state !== FormStateType.add) return;

        let nomeUsuario = CodeUtil.removeSpaces(`${model?.nome.toLowerCase()}.${model?.sobrenome?.toLowerCase()}`);
        if (!CodeUtil.isNullOrEmpty(model?.nome) && !CodeUtil.isNullOrEmpty(model?.sobrenome)) {
            setModel({ ...model, nomeUsuario: nomeUsuario ?? '' })
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code.toLowerCase() === "escape") {
            onFormClose();
            return;
        }
    }

    if (!props.show) return <></>;

    return (
        <div onKeyDown={handleKeydownEvent}>
            <ModalForm title={props.title ?? "Cadastro de Usuários"}
                errors={errors}
                show={props.show}
                state={props.state}
                onClose={onFormClose}
                isSaving={saving}
                size="2xl"
                onSave={async (e: React.MouseEvent<HTMLButtonElement> | undefined) => await onFormSave(e)}>

                <div className="grid grid-cols-12 mt-4 mb-2 px-3 pb-4">
                    <div className="col-span-12">
                        <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={(model.id ?? 0) === 0}>
                            {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                        </h2>
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6">
                        <div className="mb-1"><Label htmlFor="inputNome" value="Nome:" /></div>
                        <TextInput id="inputNome"
                            type="text"
                            readOnly={isReadOnly}
                            value={model.nome}
                            onChange={(e) => setModel({ ...model, nome: e.currentTarget.value })}
                            onBlur={generateUserName}
                            placeholder="Nome do usuário"
                            maxLength={60} />
                    </div>

                    <div className="form-control mt-5 col-span-12 md:col-span-6 md:ml-6">
                        <div className="mb-1"><Label htmlFor="inputSobrenome" value="Sobrenome:" /></div>
                        <TextInput id="inputSobrenome"
                            type="text"
                            readOnly={isReadOnly}
                            value={model.sobrenome ?? ''}
                            onChange={(e) => {
                                setModel({ ...model, sobrenome: e.currentTarget.value });
                            }}
                            onBlur={generateUserName}
                            placeholder="Sobrenome do usuário"
                            maxLength={60} />
                    </div>

                    <div className="form-control mt-5 col-span-12">
                        <div className="mb-1"><Label htmlFor="inputLogin" value="Nome de Usuário:" /></div>
                        <TextInput id="inputLogin"
                            type="text"
                            readOnly={isReadOnly}
                            value={model.nomeUsuario}
                            onChange={(e) => setModel({ ...model, nomeUsuario: e.currentTarget.value })}
                            placeholder="Nome de usuário (login)"
                            maxLength={40} />
                    </div>

                    <div className="form-control mt-5 col-span-12">
                        <div className="mb-1"><Label htmlFor="inputEmail" value="E-mail:" /></div>
                        <TextInput id="inputEmail"
                            type="email"
                            readOnly={isReadOnly}
                            addon="@"
                            value={model?.email}
                            onChange={(e) => setModel({ ...model, email: e.currentTarget.value })}
                            placeholder="email@email.com"
                            maxLength={200} />
                    </div>

                    <div className="form-control mt-8 col-span-12 flex space-x-2 items-center">
                        <Checkbox id="checkBoxBloqueado"
                            disabled={isReadOnly}
                            checked={model.isBloqueado}
                            onChange={(e) => setModel({ ...model, isBloqueado: e.currentTarget.checked })}
                            placeholder="Checked">
                        </Checkbox>

                        <Label htmlFor="checkBoxBloqueado" value="Usuário bloqueado?" />
                    </div>
                </div>
            </ModalForm>
        </div>
    );
};