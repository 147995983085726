import { FC } from "react";
import { useClientesCondutoresPage } from "./ClientesCondutoresPage.hook";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import { ClienteCondutorModel } from "../../models/cliente_condutor/ClienteCondutorModel";
import { Badge } from "flowbite-react";
import { IoMdClose } from "react-icons/io";
import { HiCheck } from "react-icons/hi";
import CodeUtil from "../../utils/CodeUtil";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { Popup } from "../../components/Popup";
import { ClientesCondutoresCadastroPage } from "./ClientesCondutoresCadastroPage";

export const ClientesCondutoresPage : FC = () => {
    const {
        isLoadingData, dataSource, setShowPopupDelete, showPopupDelete,
        selectedRow: selected, setSelectedRow: setSelected,
        pagination, setPage, modalState, onModalClose,
        inserir, consultar, excluir
    } = useClientesCondutoresPage();

    const dataTableColumns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ClienteCondutorModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        {
            property: "bloqueado", title: "Bloqueado", alignment: "center", onFormatValue(value: ClienteCondutorModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {(value.bloqueado as boolean) ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        { property: "nome", title: "Nome" },
        { property: "sobrenome", title: "Sobrenome" },
        { property: "email", title: "E-mail" },
        { property: "cpf", title: "CPF" , onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.cpf ?? "", "###.###.###-##") },
        { property: "matricula", title: "Matrícula" },
        { property: "numeroHabilitacao", title: "Habilitação" },
        { property: "celular", title: "Celular", onFormatValue: (value: ClienteCondutorModel) => CodeUtil.mask(value.celular ?? "", "(##) #####-####") },
    ];

    return (
        <>
            <CodeSpinner hidden={!isLoadingData} />

            <div className="mt-5" hidden={isLoadingData}>
                <CodeDataGrid
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "id" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Condutores",
                        subtitle: "Listagem de condutores",
                        filter: true
                    }}
                    actions={[
                        { label: 'Inserir', style: 'colored', onClick: inserir },
                        { label: 'Editar', style: 'flat', disabled: !selected, onClick: () => consultar(false) },
                        { label: 'Excluir', style: 'destructive', disabled: !selected, onClick: () => setShowPopupDelete(true) },
                        { label: 'Consultar', style: 'flat', disabled: !selected, onClick: () => consultar(true) }
                    ]}
                    columns={dataTableColumns}
                    onRowSelect={(item) => setSelected(item.selected ? item : undefined)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />

                {modalState.show && <ClientesCondutoresCadastroPage
                    show={true}
                    id={modalState.id}
                    state={modalState.state}
                    onClose={onModalClose}
                    onSave={onModalClose}
                />}

                {showPopupDelete && <Popup
                    isShowing={true}
                    message="Confirma a exclusão do condutor selecionado?"
                    isDestructive={true}
                    onConfirm={excluir}
                    onCancel={() => setShowPopupDelete(false)}
                />}
            </div>
        </>
    );
}