import { CotacaoStatusType, ModelState } from "../enums"
import { CotacaoItemModel } from "../models/cotacao/CotacaoItemModel"
import { ViewCotacaoCredenciadaModel } from "../models/cotacao/ViewCotacaoCredenciada"
import { ViewCotacaoCredenciadaItemModel } from "../models/cotacao/ViewCotacaoCredenciadaItem"

export interface CotacaoCredenciadaCommand {
    id: number | null,
    dataCadastro: Date | null,
    dataAlteracao: Date | null,
    status: CotacaoStatusType
    observacoes: string | null,
    desconto: number
    descontoPercentual: number
    frete: number
    outrasDespesas: number
    seguro: number
    valorTotal: number
    valorComissao: number
    itens: CotacaoCredenciadaItemCommand[]
}

export interface CotacaoCredenciadaItemCommand extends CotacaoItemModel {
    cotacaoItemId: number | null,
    cotacaoId: number
    credenciadaId: number
    desconto: number
    descontoPercentual: number
    frete: number
    seguro: number
    outrasDespesas: number
    valorUnitario: number
    valorTotal: number
    valorComissao: number
    observacoes: string | null
    diasGarantia: number
    state: ModelState
}


export const Map = (model?: ViewCotacaoCredenciadaModel | null): CotacaoCredenciadaCommand | null => {
    if (model === null || model === undefined)
        return null;

    return {
        id: model.id === undefined ? null : model.id,
        status: model.status,
        dataCadastro: null,
        dataAlteracao: null,
        observacoes: model.observacoes,
        desconto: model.desconto,
        descontoPercentual: model.descontoPercentual,
        frete: model.frete,
        outrasDespesas: model.seguro,
        seguro: model.outrasDespesas,
        valorTotal: model.valorTotal,
        valorComissao: model.valorComissao,
        itens: MapItens(model.itens),
    }
}

export const MapItens = (items: ViewCotacaoCredenciadaItemModel[]): CotacaoCredenciadaItemCommand[] => {
    let mappedItens: CotacaoCredenciadaItemCommand[] = [];
    items.map((model) => {
        return mappedItens.push({
            cotacaoItemId: model.id ?? null,
            cotacaoId: model.cotacaoId,
            credenciadaId: model.credenciadaId,
            observacoes: model.observacoes,
            state: model.state,
            quantidade: model.quantidade,
            desconto: model.desconto,
            descontoPercentual: model.descontoPercentual,
            frete: model.frete,
            outrasDespesas: model.seguro,
            seguro: model.outrasDespesas,
            valorTotal: model.valorTotal,
            valorComissao: model.valorComissao,
            valorUnitario: model.valorUnitario,
            diasGarantia: model.diasGarantia,
            codigoFornecedor: model.codigoFornecedor,
            descricao: model.descricao,
            numeroSerie: model.numeroSerie,
            percentualComissao: model.percentualComissao,
            servico: model.servico ? {
             id: model.servico!.id ?? 0,
             nome: model.servico!.nome
            } : null,
            tipo: model.tipo,            
            isSelected: false,
        });
    });

    return mappedItens;
}