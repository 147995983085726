import { BaseModel } from "../base";

export interface ClienteVeiculoModel extends BaseModel {
    clienteId: number;
    veiculoModeloId: number;
    modeloMarca?: string | null,
    modelo: {
        id: number;
        nome: string;
    };
    marca: {
        id: number;
        nome: string;
        logomarca?: string;
    };
    anoFabricacao: number;
    anoModelo: number;
    placa: string;
    renavam: string;
    inativo: boolean;
    state: 'UNCHANGED' | 'ADDED' | 'UPDATED' | 'DELETED'
}

export const defaultClienteVeiculo: ClienteVeiculoModel = {
    clienteId: 0,
    veiculoModeloId: 0,
    anoFabricacao: (new Date()).getFullYear(),
    anoModelo: (new Date()).getFullYear(),
    renavam: '',
    placa: '',
    state: 'UNCHANGED',
    isSelected: false,
    marca: {
        id: 0,
        nome: ''
    },
    modelo: {
        id: 0,
        nome: ''
    },
    inativo: false
}