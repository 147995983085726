import { useState } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { ClienteModel, defaultCliente } from "../../../models/cliente/ClienteModel";
import { ClienteVeiculoModel } from "../../../models/cliente_veiculo/ClienteVeiculoModel";
import { FluentValidator } from "../../../types";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { ShowToast } from "../../../components/CodeToast";
import { RelatorioService } from "../../../services/RelatorioService";
import { UNKOWN_EXCEPTION } from "../../../utils/Constants";
import CodeUtil from "../../../utils/CodeUtil";

export const useModalTabDados = (props: ModalFormTabProps<CotacaoModel>) => {
    const [model, setModel] = props.model;
    const [errors, setErrors] = useState<FluentValidator[]>([]);

    const handleImprimir = async () => {
        try {
            ShowToast({ message: "Impressão solicitada! Enviaremos uma notificação quando a impressão estiver pronta." });
            const report = await RelatorioService.gerarImpressaoCotacao(model.id!);

            if (!report) {
                ShowToast({ message: UNKOWN_EXCEPTION });
                return;
            }
    
            CodeUtil.generateTempFileUrl(report);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }

    const onSearchCliente = (result: unknown): void => {
        if (!result) {
            setModel({ ...model, cliente: null });
            return;
        }

        setModel({
            ...model,
            cliente: {
                ...defaultCliente,
                ...(result as ClienteModel),
                id: (result as ClienteModel).id!,
            },            
        });
    };

    const onSearchVeiculo = (result: unknown): void => {
        if (!result) {
            setModel({ ...model, veiculo: null });
            return;
        }

        setModel({ ...model, veiculo: (result as ClienteVeiculoModel) });
    };

    return { model, setModel, onSearchCliente, onSearchVeiculo, errors, setErrors, handleImprimir };
}