import { useState } from "react";
import { ICodeDataGridColumn } from "../../components/CondensedCodeDataGrid/types";
import { format } from "fecha";
import { EnumRelatorio, RelatorioModel, getTipoRelatorio } from "../../models/relatorios/RelatorioModel";
import { IFiltroRelatorioCotacoes } from "./types";
import CodeUtil from "../../utils/CodeUtil";
import { CotacaoStatusType } from "../../enums";
import { SessionManager } from "../../utils/SessionManager";
import { RelatorioService } from "../../services/RelatorioService";
import { ShowToast } from "../../components/CodeToast";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";

export const useRelatorioCotacoesPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showFiltroCotacoes, setShowFiltroCotacoes] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<RelatorioModel[]>([]);
    const [filtroCotacoes, setFiltroCotacoes] = useState<IFiltroRelatorioCotacoes>({
        dataInicial: format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD'),
        dataFinal: format(new Date(Date.now()), 'YYYY-MM-DD'),
        status: [CotacaoStatusType.AGUARDANDO,
        CotacaoStatusType.COTACAO_ENVIADA,
        CotacaoStatusType.APROVADA]
    });

    const columns: ICodeDataGridColumn[] = [
        { title: "Data", property: "data", alignment: "center", onFormatValue: (item: RelatorioModel) => <span>{format(item.data, "DD/MM/YYYY HH:mm")}</span> },
        { title: "Relatório", property: "relatorio", onFormatValue: (item: RelatorioModel) => <span>{getTipoRelatorio(item.tipo)}</span> },
        { title: "Link", property: "link", onFormatValue: (item: RelatorioModel) => <a href={item.link} target="_blank" rel="noreferrer">{item.link}</a> },
    ]

    const handleGerarRelatorioCotacoes = (params: IFiltroRelatorioCotacoes) => {
        setShowFiltroCotacoes(false);
        setLoading(true);
        setFiltroCotacoes({ ...filtroCotacoes, ...params });

        console.log(params);

        RelatorioService.gerarRelatorioCotacoesAsync(params)
            .then(async (report) => {
                if (!report) {
                    ShowToast({ message: "Erro desconhecido ao gerar o relatório. Tente novamente mais tarde." });
                    return;
                }

                const downloadLink = CodeUtil.generateTempFileUrl(report);

                setDataSource([{
                    data: new Date(Date.now()),
                    tipo: EnumRelatorio.COTACOES,
                    isSelected: true,
                    link: downloadLink,
                    usuarioId: SessionManager.getSession().user?.id ?? 0
                }, ...dataSource]);

            }).catch((error) => {
                console.log(error);
                ShowToast({ message: UNKOWN_EXCEPTION });
            }).finally(() => {
                setLoading(false);
            });
    }

    return {
        loading, columns,
        showFiltroCotacoes, setShowFiltroCotacoes,
        filtroCotacoes, setFiltroCotacoes,
        dataSource, handleGerarRelatorioCotacoes
    }
}