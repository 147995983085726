import { FC } from "react";
import styled from "styled-components";
import { SideBarItem } from "./Item";
import { SideBarListItems } from "./ListItems";
import { FaToolbox, FaUserFriends, FaFileInvoice, FaUser, FaCar, FaCog, FaPrint, FaBookOpen, FaCreditCard, FaIdCard } from 'react-icons/fa'
import { RiApps2Fill } from 'react-icons/ri'
import { SidebarDropdownListItems } from "./DropdownListItems";
import { SidebarHeader } from "./Header";
import { SidebarProps } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import { SessionManager } from "../../utils/SessionManager";
import AuthService from "../../services/AuthService";
import { MdLogout } from "react-icons/md";
import { UserType } from "../../enums";

export const Sidebar: FC<SidebarProps> = (props) => {

    const sessionContext = useSessionContext();
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const isRouteActive = (expected: string): boolean => {
        return currentLocation.pathname.includes(expected)
    }
    const handleSidebarOutsideClick = () => {
        SessionManager.setSession({ ...SessionManager.getSession(), hiddenSidebar: true });
        sessionContext.refreshSession();

        props.onItemClick?.call(this);
    }

    const handlerLogout = () => {

        AuthService.logout();
        sessionContext.refreshSession();

        navigate("/auth/login");
    }

    if (props.hidden) return <></>;

    return (
        <StyledSidebar>
            <div id="sidebar-container" className="flex">
                <div className="sidebar overflow-y-scroll py-4 px-3 shadow-sm">
                    <SidebarHeader />

                    <SideBarListItems>
                        <SideBarItem onClick={handleSidebarOutsideClick} to="/home" label="Home" icon={<RiApps2Fill className="w-5 h-5 transition duration-75 text-gray-500" />} />

                        {SessionManager.getSession().user?.tipoUsuario !== UserType.dealer &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/cotacoes" label="Cotações" icon={<FaFileInvoice className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.dealer &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/credenciadas/cotacoes" label="Cotações" icon={<FaFileInvoice className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/clientes" label="Clientes" icon={<FaUserFriends className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.customer &&
                            <>
                                <SideBarItem onClick={handleSidebarOutsideClick} to="/clientes/veiculos" label="Frota" icon={<FaCar className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />
                                <SideBarItem onClick={handleSidebarOutsideClick} to="/clientes/condutores" label="Condutores" icon={<FaIdCard className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />
                            </>
                        }

                        {SessionManager.getSession().user?.tipoUsuario === UserType.customer &&
                            <SideBarItem onClick={handleSidebarOutsideClick}
                                icon={<FaToolbox className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />}
                                to="/credenciadas/empresas" label="Credenciadas" />}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <SidebarDropdownListItems label="Redes Credenciadas"
                                expanded={isRouteActive("/credenciadas")} icon={<FaToolbox className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />}>
                                <SideBarItem onClick={handleSidebarOutsideClick} to="/credenciadas/empresas" dropdown label="Credenciadas" />
                                <SideBarItem onClick={handleSidebarOutsideClick} to="/credenciadas/servicos" dropdown label="Serviços" />
                            </SidebarDropdownListItems>}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/marcas-e-modelos" label="Marcas/Modelos" icon={<FaCar className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/catalogos" label="Catálogos" icon={<FaBookOpen className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        <SideBarItem onClick={handleSidebarOutsideClick} to="/usuarios" label="Usuários" icon={<FaUser className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />

                        <hr />

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <>
                                <SidebarDropdownListItems label="Cartão de Benefícios"
                                    expanded={isRouteActive("/veloe")} icon={<FaCreditCard className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />}>
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/veiculos" dropdown={true} label="Veículos" />
                                    <hr />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/historico_abastecimento" dropdown={true} label="Abastecimento" />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/historico_transacoes" dropdown={true} label="Transações" />
                                    <hr />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/cartoes" dropdown={true} label="Cartões Benefício" />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/saldo_conta" dropdown={true} label="Consultar Saldo" />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/recarga_complementar" dropdown={true} label="Recargas" />
                                    <SideBarItem onClick={handleSidebarOutsideClick} to="/veloe/rotas" dropdown={true} label="Rotas" />
                                </SidebarDropdownListItems>
                                <hr />
                            </>
                        }


                        <SidebarDropdownListItems label="Relatórios"
                            expanded={isRouteActive("/relatorios")} icon={<FaPrint className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />}>
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/relatorios/cotacoes" dropdown={true} label="Cotações" />
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/relatorios/ordens_servico" dropdown={true} label="Ordens de Serviço" />
                            {SessionManager.getSession().user?.tipoUsuario !== UserType.customer &&
                                <SideBarItem onClick={handleSidebarOutsideClick} to="/relatorios/repasses" dropdown={true} label="Repasses" />}
                        </SidebarDropdownListItems>

                        {SessionManager.getSession().user?.tipoUsuario === UserType.admin &&
                            <SideBarItem onClick={handleSidebarOutsideClick} to="/configuracoes" label="Configurações" icon={<FaCog className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />}

                        <SideBarItem onClick={handlerLogout} textColor="text-red-600"
                            icon={<MdLogout className="w-5 h-5 transition duration-75 text-red-600" />}
                            label="Sair do Sistema" />
                    </SideBarListItems>
                </div>

                <div className="sidebar-box" onClickCapture={handleSidebarOutsideClick} />
            </div>
        </StyledSidebar>
    )
};

const StyledSidebar = styled.aside`
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 50;

    #sidebar-container > .sidebar-box {
        background-color: rgba(0, 0, 0, 0.35);
        min-height: 100vh;
        margin-left: 60;
        width: calc(100% - 60px);
    }

    #sidebar-container > .sidebar {
        height: 100vh;
        background-color: rgb(255, 255, 255);
        min-width: 16rem;
        width: 60px;
    }
`;