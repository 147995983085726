import { format } from "fecha";

export default class CodeUtil {
    public static addDays(days: number, date?: Date): Date {
        let newDate = date ?? new Date(Date.now());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    public static arrayLength(data?: string[], ignoreEmpty: boolean = true): number {
        if (data === undefined || data === null)
            return 0;

        if (!ignoreEmpty) {
            return data?.length ?? 0;
        }

        return data.filter((e: string) => e !== undefined && e !== '' && e !== null)?.length ?? 0;
    }

    public static arrayToStr(messages?: string[]): string {
        if (messages === undefined || messages === null || messages.length === 0)
            return '';

        let message = '';
        messages.map((item) => message += `${item}`);

        return message;
    }

    public static castDateTimeToDate = (value?: string | null, _default?: string | null): string | null => this.isNullOrEmpty(value) ?
        (_default ?? null) : value!.substring(0, value!.indexOf('T'));

    public static stringToDate = (date: string | null, _default: Date | number = Date.now()) : Date => {
        let data = new Date(date ?? _default);
        data.setDate(data.getDate() + 1);
        return data;
    } 
    
    public static formatStringDate = (date: string | null, mask: string = 'DD/MM/YYYY', _default: Date | number = Date.now()) : string => {
        const data = this.stringToDate(date, _default);
        return format(data, mask);
    }

    public static getPropertyValue(row: any, name: string): any {
        let columns = name.split('.');

        if (columns.length <= 0)
            return '';

        if (columns.length === 1)
            return row[columns[0]];

        let obj = row;
        columns.map((column) => obj === null || obj === undefined ? null : obj = obj[column]);
        return obj;
    }

    public static base64toBlobAsync = async (base64: string, type: string) => await fetch(`data:${type};base64,${base64}`).then(res => res.blob());

    public static downloadPDFAsync = async (base64String: string): Promise<string> => {
        const linkSource = `data:application/json;base64,${base64String}`;

        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = linkSource;
        downloadLink.target = "_blank";
        downloadLink.rel = "noreferrer";
        downloadLink.click();

        return linkSource;
    }

    public static isNullOrEmpty = (text: string | undefined | null): boolean => !text || text?.trim() === '';

    public static isNumeric = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

    public static isUndefined = (obj?: any | null) => obj === undefined || obj === null;

    public static isValidDate(value?: string): boolean {
        try {
            if (this.isNullOrEmpty(value)) return false;

            let day = Number(value?.replaceAll('/', '',).substring(0, 2));
            let month = Number(value?.replaceAll('/', '',).substring(2, 4));
            let year = Number(value?.replaceAll('/', '',).substring(4, 8));

            return new Date(year, month, day).toString() !== 'Invalid Date';
        } catch (error) {
            return false;
        }
    }

    public static isValidEmail(email?: string): boolean {
        if (email === null)
            return false;

        return email?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
    }

    public static mask(value: string | null, mask: string = "", symbol: string = "#") {
        if (CodeUtil.isNullOrEmpty(value)) return "";

        let maskLength = Array.from(mask).filter((val) => val === symbol).length;
        if (value!.length < maskLength)
            return CodeUtil.removeSpecialChars(value);

        let maskedData = mask;
        Array.from(value!).map((char) => maskedData = maskedData.replace(symbol, char));
        return maskedData;
    }

    public static moneyFormat = (value: number | any, cipher: boolean = true) => Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: "BRL"
    }).format(value)
        .replaceAll(cipher ? "" : "R$", "")
        .trim();

    public static numberFormat = (value: number | any) => Intl.NumberFormat('pt-BR', {
        style: 'decimal'
    }).format(value).trim();

    public static moneyAsNumber = (moneyFormat: string | any, cipher: boolean = true) =>
        Number(moneyFormat.replaceAll(cipher ? "" : "R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
            .trim());


    public static parseDate(value?: string | null): Date | null {
        try {
            if (this.isNullOrEmpty(value)) return null;

            let day = Number(value?.replaceAll('/', '',).substring(0, 2));
            let month = Number(value?.replaceAll('/', '',).substring(2, 4));
            let year = Number(value?.replaceAll('/', '',).substring(4, 8));

            return new Date(year, month, day);
        } catch (error) {
            return null;
        }
    }

    public static parseInt = (value?: any) => value === null ? null : Number.parseInt(value);

    public static parseFloat = (value?: any) => value === null ? null : Number.parseFloat(value);

    public static removeSpecialChars = (value?: string | null): string => value?.replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('/', '')
        .replaceAll('_', '')
        .replaceAll('-', '')
        .trim() ?? '';

    public static removeSpaces = (value?: string) => value?.replaceAll(' ', '').trim();

    public static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    public static generateTempFileUrl = (report: Blob, open: boolean = true): string => {
        var url = window.URL || window.webkitURL;
        var linkUrl = url.createObjectURL(report);        

        if (open) window.open(linkUrl, "_blank");
        
        return linkUrl;
    }
}