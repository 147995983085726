import { FC, useRef } from "react";
import CodeSpinner from "../CodeSpinner";
import { useOrdemServicoPanel } from "./index.hook";
import { CondensedCodeDataGrid } from "../CondensedCodeDataGrid";
import { ContextMenu } from "primereact/contextmenu";
import { FormStateType } from "../../enums";
import { CotacoesCredenciadasCadastroPage } from "../../pages/cotacoes_credenciadas_cadastro";
import { SideBarFiltrosOrdensServico } from "../SidebarFiltroOrdensServico";
import { EncerramentoServicoModal, IniciarServicoModal } from "./components";

export const OrdemServicoPanel: FC = () => {
    const {
        loading, form, dataSource, pagination,
        formIniciarServico, formEncerramento, hiddenMoreFilters, setHiddenMoreFilters, filtros, setFiltros, onApplyFilter,
        getColumns, onPageChanged, getContextMenuItems, onRowSelect
    } = useOrdemServicoPanel();
    const cm = useRef<ContextMenu>(null);

    return (
        <>
            <CodeSpinner hidden={!loading} />

            <div hidden={loading} className="-m-4 p-0">
                <ContextMenu ref={cm} model={getContextMenuItems()} />

                <CondensedCodeDataGrid
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "id" }}
                    datasource={dataSource}
                    striped={true}
                    onRowSelect={onRowSelect}
                    onContextMenu={(e) => cm.current?.show(e)}
                    header={{ filter: true }}
                    onMoreFilter={() => setHiddenMoreFilters(false)}
                    columns={getColumns()}
                    pagination={{ ...pagination, onPageChanged: onPageChanged }}
                />

                {form.show && <CotacoesCredenciadasCadastroPage
                    show={form.show}
                    credenciadaId={form.credenciadaId}
                    id={form.id}
                    state={FormStateType.view}
                    onClose={form.onClose}
                />}


                {formIniciarServico.show && <IniciarServicoModal
                    {...formIniciarServico}
                    title="Registrar Entrada do Veículo"
                    state={FormStateType.edit}
                />}

                {formEncerramento.show && <EncerramentoServicoModal
                    {...formEncerramento}
                    title="Encerrar Ordem de Serviço"
                    state={FormStateType.edit}
                />}

                <SideBarFiltrosOrdensServico
                    filter={[filtros, setFiltros]}
                    hidden={hiddenMoreFilters}
                    onClose={() => setHiddenMoreFilters(true)}
                    onApplyFilter={onApplyFilter} />
            </div>
        </>
    );
};