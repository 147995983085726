import { useCallback, useState, MouseEvent, useEffect } from "react"
import { ModalFormProps } from "../../components/ModalForm/types"
import { CotacaoStatusType, FormStateType } from "../../enums"
import { ShowToast } from "../../components/CodeToast"
import { UNKOWN_EXCEPTION } from "../../utils/Constants"
import { ViewCotacaoCredenciadaModel, getDefaultViewCotacaoCredenciada } from "../../models/cotacao/ViewCotacaoCredenciada"
import { CotacaoCredenciadaService } from "../../services/CotacaoCredenciadaService"
import { SessionManager } from "../../utils/SessionManager"
import { Map } from "../../commands/CotacaoCredenciadaCommand"
import { ConfiguracaoService } from "../../services/ConfiguracaoService"
import { ConfiguracaoModel } from "../../models/configuracao/ConfiguracaoModel"
import CodeUtil from "../../utils/CodeUtil"
import { format } from "fecha"

type FormProps = {
    loaded: boolean
    readonly: boolean
    saving?: boolean
}

export type CotacaoCredenciadaCadastroFormProps = {
    credenciadaId?: number | null
} & ModalFormProps;

export const useCotacaoCredenciadaCadastroPage = (props: CotacaoCredenciadaCadastroFormProps) => {
    const [model, setModel] = useState<ViewCotacaoCredenciadaModel>(getDefaultViewCotacaoCredenciada());
    const [formProps, setFormProps] = useState<FormProps>({ loaded: false, readonly: props.state === FormStateType.view });
    const credenciadaId: number = SessionManager.getSession().user?.credenciadaId ?? props.credenciadaId ?? 0;

    const onFormLoad = useCallback(async () => {
        if (!props.show || formProps.loaded) return;

        try {
            if (!props.id || props.id === 0) {
                const configuracoes: ConfiguracaoModel = (await ConfiguracaoService.get()).data ?? { percentualComissao: 0, prazoValidade: 0 };
                setModel({
                    ...getDefaultViewCotacaoCredenciada(),
                    dataValidade: format(CodeUtil.addDays(configuracoes.prazoValidade), 'YYYY-MM-DD')
                });

                setFormProps({ ...formProps, loaded: true });
                return;
            }

            const response = await CotacaoCredenciadaService.getId(props.id ?? 0, credenciadaId, true);

            if (!response.success) {
                ShowToast({ message: response.messages });
                props.onClose?.call(this);
                return;
            }

            setModel(response.data);
            setFormProps({ ...formProps, loaded: true });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [props, credenciadaId, formProps, setFormProps]);

    const onFormClose = () => {
        setFormProps({ ...formProps, loaded: false });
        props.onClose?.call(this);
    };

    const onFormSave = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
        if (!await saveCotacao()) return;

        props.onSave?.call(this, e);
        setFormProps({ ...formProps, loaded: false });
    };

    const fetchFormProps = (): FormProps => formProps;

    const saveCotacao = async (): Promise<boolean> => {
        try {
            setFormProps({ ...formProps, saving: true });

            if (props.state === FormStateType.edit)
                return await update();
            else
                return await insert();
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        } finally {
            setFormProps({ ...formProps, saving: false });
        }
    }

    const insert = async (): Promise<boolean> => {
        try {
            let command = { ...Map(model), status: CotacaoStatusType.COTACAO_ENVIADA };

            const response = await CotacaoCredenciadaService.insert({
                ...model,
                ...command,
                credenciadaId,
                itens: command.itens,
                clienteId: model.cliente?.id,
                veiculoId: model.veiculo?.id
            });

            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    const update = async (): Promise<boolean> => {
        try {
            let command = { ...Map(model), status: CotacaoStatusType.COTACAO_ENVIADA };
            const response = await CotacaoCredenciadaService.update(model.id!, credenciadaId, command);
            if (!response.success)
                ShowToast({ message: response.messages });
            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    };

    const showHistoricoEntradaSaida = (): boolean => {
        return model.status === CotacaoStatusType.APROVADA ||
            model.status === CotacaoStatusType.EMEXECUCAO ||
            model.status === CotacaoStatusType.ENCERRADA ||
            model.status === CotacaoStatusType.REPROVADA;
    }

    useEffect(() => {
        onFormLoad();
    });

    return { onFormClose, onFormSave, onFormLoad, fetchFormProps, model, setModel, showHistoricoEntradaSaida }
}