import { ClienteVeiculoModel } from "../models/cliente_veiculo/ClienteVeiculoModel";

export interface ClienteVeiculoCommand 
{
    id?: number | null;
    clienteId: number;
    modeloVeiculoId: number;
    anoFabricacao: number;
    anoModelo: number;
    placa: string;
    renavam: string;
    inativo: boolean;
}

export const MapAsCommand = (model?: ClienteVeiculoModel | null): ClienteVeiculoCommand | null => {
    if (model === null || model === undefined)
        return null;

    return {
        id: model.id,
        anoFabricacao: model.anoFabricacao,
        anoModelo: model.anoModelo,
        clienteId: model.clienteId,
        modeloVeiculoId: model.modelo.id,
        placa: model.placa,
        renavam: model.renavam,
        inativo: model.inativo
    }
}

