import { FC, useCallback, useEffect, useRef, useState } from "react";
import ModalForm from "../../../components/ModalForm";
import { ModalFiltroRepassesProps } from "../types";
import { CotacaoStatusType, FormStateType } from "../../../enums";
import { format } from "fecha";
import { CodeLabel } from "../../../components/CodeLabel";
import { CodeSelectableChip } from "../../../components/CodeSelectableChip";
import { getCotacaoStatus } from "../../../models/cotacao/CotacaoModel";
import { TextInput } from "flowbite-react";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { useCredenciadaColumns } from "../../../models/credenciada/useCredenciadaColumns";
import { CredenciadaModel } from "../../../models/credenciada";
import { ICodePesquisaValue } from "../../../components/CodePesquisa/types";
import CodeUtil from "../../../utils/CodeUtil";
import { SessionManager } from "../../../utils/SessionManager";

export const FiltroRelatorioRepasses: FC<ModalFiltroRepassesProps> = (props) => {
    const STATUS_ITEMS = [CotacaoStatusType.CANCELADA, CotacaoStatusType.APROVADA, 
        CotacaoStatusType.EMEXECUCAO, CotacaoStatusType.ENCERRADA];
    const inputDataInicial = useRef<HTMLInputElement>(null);
    const inputDataFinal = useRef<HTMLInputElement>(null);
    const [filter, setFilter] = props.model;
    const [status, setStatus] = useState<CotacaoStatusType[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [credenciadaPesquisa, setCredenciadaPesquisa] = useState<ICodePesquisaValue | null>(null);
    const columnsCredenciada = useCredenciadaColumns();
    const currentUser = SessionManager.getSession().user;

    const loadDefaultValues = useCallback(() => {
        if (inputDataInicial.current !== undefined && inputDataInicial.current !== null)
            inputDataInicial.current.value = filter.dataInicial ?? format(new Date(Date.now()), "YYYY-MM-DD");

        if (inputDataFinal.current !== undefined && inputDataFinal.current !== null)
            inputDataFinal.current.value = filter.dataFinal ?? format(new Date(Date.now()), "YYYY-MM-DD");

        setStatus(filter?.status ?? []);
    }, [filter, setStatus]);

    const onFormLoaded = useCallback(() => {
        if (loaded) return;

        loadDefaultValues();
        setLoaded(true);
    }, [loaded, loadDefaultValues]);

    const handleSelectStatus = (model: unknown) => {
        const value = model as CotacaoStatusType;
        if (status?.includes(value))
            setStatus(status.filter((element) => element !== value!) ?? []);
        else
            setStatus(prev => [...prev ?? [], value]);
    };

    const handleClose = () => {
        loadDefaultValues();
        props.onClose?.call(this);
    }

    const handleApplyFilter = () => {
        let filtered = {
            ...filter,
            dataInicial: inputDataInicial.current?.value,
            dataFinal: inputDataFinal.current?.value,
            status: status,            
        };

        if (currentUser?.credenciadaId) {
            filtered.credenciadaId = currentUser.credenciadaId
        }

        setFilter(filtered);
        props.onApplyFilter?.call(this, filtered);
    }

    const handleSelecionarCredenciada = (result: unknown) => {
        if (!result) return;

        setCredenciadaPesquisa({
            text: (result as CredenciadaModel).razaoSocial,
            value: (result as CredenciadaModel).id
        });
    }

    useEffect(() => {
        onFormLoaded();
    }, [onFormLoaded]);

    return (
        <ModalForm
            show={props.show}
            state={FormStateType.edit}
            size="3xl"
            errors={[]}
            title="Filtrar Relatório"
            onClose={handleClose}
            onSave={handleApplyFilter}
            saveButtonTitle={'Gerar'}
            isSaving={false}
        >
            <div className="grid grid-cols-12 gap-x-3 p-5">
                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Inicio:" className="mb-1" htmlFor="inputDataInicial" />
                    <TextInput type={"date"}
                        id="inputDataInicial"
                        ref={inputDataInicial}
                        defaultValue={format(CodeUtil.addDays(-30, new Date(Date.now())), 'YYYY-MM-DD')} />
                </div>

                <div className="col-span-6 md:col-span-4">
                    <CodeLabel value="Término:" className="mb-1" htmlFor="inputDataFinal" />
                    <TextInput type={"date"}
                        id="inputDataFinal"
                        ref={inputDataFinal}
                        defaultValue={format(new Date(Date.now()), 'YYYY-MM-DD')} />
                </div>

                {!currentUser?.credenciadaId &&
                <div className="col-span-12 mt-5">
                    <CodePesquisa
                        id="cpsCredenciada"
                        valueField={{ label: "Código:", property: "id", value: credenciadaPesquisa?.value === 0 ? "" : credenciadaPesquisa?.value?.toString() }}
                        textField={{ label: "Nome do Cliente:", property: "razaoSocial", value: credenciadaPesquisa?.text ?? "" }}
                        modal={{
                            title: "Pesquisa de Credenciadas",
                            dataColumns: columnsCredenciada,
                            sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                        }}
                        onSearch={handleSelecionarCredenciada}
                        endpoint="credenciada" />
                </div>}

                <div className="col-span-12 mt-4 gap-x-3">
                    <CodeLabel value="Status:" className="mb-1" />
                    {STATUS_ITEMS.map((item) => <CodeSelectableChip
                        key={item}
                        model={item}
                        className="m-0.5 text-sm"
                        selected={status?.includes(item)}
                        onSelectChip={handleSelectStatus}
                        label={getCotacaoStatus(item)} />)}
                </div>
            </div>
        </ModalForm>
    );
};