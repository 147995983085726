import { useState, MouseEvent } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { FormStateType, ModelState } from "../../../enums";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import { CotacaoItemModel, getCotacaoItemDefault } from "../../../models/cotacao/CotacaoItemModel";

export interface IModalState {
    show: boolean
    state: FormStateType,
}

export const useModalTabItens = (props: ModalFormTabProps<CotacaoModel>) => {
    const [model, setModel] = props.model;
    const [item, setItem] = useState<CotacaoItemModel>(getCotacaoItemDefault());
    const [itemIndex, setItemIndex] = useState<number | null>(null);
    const [modalState, setModalState] = useState<IModalState>({ show: false, state: FormStateType.add });
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const readonly = props.state === FormStateType.view;

    const handleModalClose = () => {
        setModalState({ ...modalState, show: false });
    }

    const handleModalSave = (e: MouseEvent<HTMLButtonElement> | undefined) => {

        if (modalState.state === FormStateType.edit) {
            let _items = model.itens;
            _items[itemIndex ?? -1] = item;
            _items[itemIndex ?? -1].state = ModelState.updated;

            setModel({
                ...model,
                itens: _items
            });

            setModalState({ ...modalState, show: false });
            return;
        }

        setModel({
            ...model,
            itens: [...model.itens, item],
        });

        setModalState({ ...modalState, show: false });
    }

    const handleButtonInserir = async () => {
        setItem({
            ...getCotacaoItemDefault(),
            state: ModelState.added,
            percentualComissao: model.cliente?.percentualComissaoRodar ?? 0            
        });
        setModalState({
            show: true,
            state: FormStateType.add
        });
    }

    const handleButtonEditar = (item: CotacaoItemModel) => {
        setItem(item ?? getCotacaoItemDefault());
        setModalState({
            show: true,
            state: readonly ? FormStateType.view : FormStateType.edit
        });
    }

    const handleButtonExcluirPopupDelete = () => {
        try {
            if (itemIndex === null) return;

            if ((itemIndex ?? -1) < 0) return;

            let _items = model.itens;
            _items[itemIndex].state = ModelState.deleted;


            setModel({
                ...model,
                itens: _items
            });

            setItem(getCotacaoItemDefault());
            setItemIndex(null);
        } finally {
            setShowPopupDelete(false);
        }
    };

    const handleButtonDelete = () => setShowPopupDelete(true);

    const handleButtonClosePopupDelete = () => setShowPopupDelete(false);

    return {
        setItemIndex,
        modalState, handleModalClose, handleModalSave,
        handleButtonExcluirPopupDelete, handleButtonClosePopupDelete,
        model, setModel,
        item, setItem,
        showPopupDelete,
        handleButtonInserir, handleButtonDelete, handleButtonEditar,
        readonly
    };
};