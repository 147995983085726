import { Spinner, TextInputProps } from "flowbite-react";
import { FC, useState, KeyboardEvent, useEffect, useCallback } from "react";
import { FiSearch } from "react-icons/fi";
import { FormStateType } from "../../enums";
import CodeUtil from "../../utils/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../utils/Constants";
import { CodeLabel } from "../CodeLabel";
import { ShowToast } from "../CodeToast";
import { CodePesquisaModal } from "./components/CodePesquisaModal";
import { CodePesquisaService } from "./services/CodePesquisaService";
import { ICodePesquisaField, ICodePesquisaModal } from "./types";

export type CodePesquisaProps = {
    id?: string
    name?: string
    textField: ICodePesquisaField
    valueField: ICodePesquisaField
    modal: ICodePesquisaModal
    endpoint: string
    params?: string
    onSearch?: (result: unknown) => void
} & TextInputProps;



export const CodePesquisa: FC<CodePesquisaProps> = (props) => {
    const [searching, setSearching] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const [currentValue, setCurrentValue] = useState<number | string>(props.valueField.value ?? "");
    const [currentText, setCurrentText] = useState<number | string>(props.textField.value ?? "");

    const clearSearch = () => {
        setShowModal(false);
        setSearching(false);

        setCurrentValue("");
        setCurrentText("");

        props.onSearch?.call(this, null);
    }

    const searchId = async (): Promise<boolean> => {
        try {
            if (!currentValue || Number(currentValue) === 0) return false;

            const response = await CodePesquisaService.getId(props.endpoint, currentValue);
            if (response.success) {
                props.onSearch?.call(this, response.data[0]);
                setCurrentText(CodeUtil.getPropertyValue(response.data[0], props.textField.property));
            }

            return response.success;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return false;
        }
    }

    const search = async () => {
        if (props.readOnly || props.disabled) return;

        setCurrentValue("");
        setCurrentText("");

        if (!currentValue || Number(currentValue) === 0) {
            props.onSearch?.call(this, null);
            return;
        }

        await searchModal();
    }

    const searchModal = async () => {
        if (props.readOnly || props.disabled) return;

        setSearching(true);
        try {
            if (await searchId()) return;
            setShowModal(true);
        } finally {
            setSearching(false);
        }
    }

    const handleInputValueKeyDownEvent = (e: KeyboardEvent<HTMLDivElement> | undefined) => {
        if (e?.code?.toLowerCase().endsWith("enter") ?? false) {
            search();
            return;
        }
    }

    const handleModalKeyDownEvent = (e: KeyboardEvent<HTMLDivElement> | undefined) => {
        if ((e?.code?.toLowerCase() === "escape" ?? false) && showModal) {
            setShowModal(false);
            clearSearch();
            return;
        }
    }

    const onComponentLoad = useCallback(async () => {
        await setCurrentValue(props.valueField.value ?? "");
        await setCurrentText(props.textField.value ?? "");
    }, [setCurrentValue, setCurrentText, props]);

    useEffect(() => { onComponentLoad() }, [onComponentLoad]);

    return (
        <div className="form-control" onKeyDown={handleModalKeyDownEvent}>
            <div className="grid grid-cols-12 gap-x-1.5">
                <div className="col-span-4 md:col-span-3 lg:col-span-3">
                    <CodeLabel className="mb-1" htmlFor={props.id} value={props.valueField.label} />
                    <div className="flex">
                        <input
                            id={props.id}
                            type={"text"}
                            onKeyDown={handleInputValueKeyDownEvent}
                            readOnly={props.readOnly || searching}
                            className="rounded-none rounded-l-lg bg-gray-50 border border-gray-300 text-gray-900 block 
                            focus:ring-black focus:border-black flex-1 min-w-0 w-full text-sm p-2.5"
                            value={currentValue}
                            onChange={(e) => {
                                if (props.disabled) {
                                    e.preventDefault();
                                    return;
                                }

                                if (!CodeUtil.isNullOrEmpty(e.currentTarget.value) && !e.currentTarget.value.match("^[0-9]+$")) {
                                    e.preventDefault();
                                    return;
                                };

                                setCurrentValue(e.currentTarget.value);
                            }}
                            onBlur={search} />

                        <button onClick={searchModal} tabIndex={-1} disabled={props.readOnly || searching}
                            className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 focus:ring-black focus:border-black 
                                hover:bg-gray-300 border border-gray-300 rounded-r-md">
                            <span>{searching ? <Spinner color={"gray"} size={"sm"} /> : <FiSearch />}</span>
                        </button>
                    </div>
                </div>

                <div className="col-span-8 md:col-span-9 lg:col-span-9">
                    <CodeLabel className="mb-1" htmlFor={`${props.id}-text`} value={props.textField.label} />
                    <input type="text"
                        id={`${props.id}-text`}
                        tabIndex={-1}
                        readOnly={true}
                        className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 block 
                            focus:ring-black focus:border-black flex-1 min-w-0 w-full text-sm p-2.5"
                        placeholder={props.placeholder}
                        value={currentText} />
                </div>
            </div>

            <CodePesquisaModal
                show={showModal}
                endpoint={props.endpoint}
                params={props.params}
                modalInfo={props.modal}
                title={props.modal.title}
                state={FormStateType.view}
                onItemSelect={(item) => {
                    if (!item) return;

                    props.onSearch?.call(this, item);
                    setCurrentValue(CodeUtil.getPropertyValue(item, props.valueField.property));
                    setCurrentText(CodeUtil.getPropertyValue(item, props.textField.property));
                }}
                onClose={() => setShowModal(false)} />
        </div>
    );
};