import { FC } from "react";
import { useClientesFrotasPage } from "./index.hook";
import { ICodeDataGridColumn } from "../../components/CodeDataGrid/types";
import { ClienteVeiculoModel } from "../../models/cliente_veiculo/ClienteVeiculoModel";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { Popup } from "../../components/Popup";
import { Badge } from "flowbite-react";
import { IoMdClose } from "react-icons/io";
import { HiCheck } from "react-icons/hi";
import { ClientesFrotasCadastroPage } from "../clientes_frotas_cadastro";

export const ClientesFrotasPage: FC = () => {
    const { isLoadingData, dataSource, setShowPopupDelete, showPopupDelete,
        selectedRow: selected, setSelectedRow: setSelected,
        pagination, setPage, modalState, onModalClose,
        inserir, consultar, excluir
    } = useClientesFrotasPage();

    const dataTableColumns: ICodeDataGridColumn[] = [
        { title: "número", property: "id", alignment: "center", onFormatValue: (value: ClienteVeiculoModel) => <span>{String(value.id).padStart(4, "0")}</span> },
        {
            property: "bloqueada", title: "Ativo", alignment: "center", onFormatValue(value: ClienteVeiculoModel) {
                return (
                    <div className="flex justify-center flex-wrap items-center gap-2">
                        {(value.inativo as boolean) ? (
                            <Badge size="sm" color="failure" icon={IoMdClose} />
                        ) : (
                            <Badge icon={HiCheck} size="sm" color="success" />
                        )}
                    </div>
                );
            },
        },
        { title: "marca", property: "marca.nome" },
        { title: "modelo", property: "modelo.nome" },
        { title: "placa", property: "placa" },
        { title: "renavam", property: "renavam" },
    ];

    return (
        <>
            <CodeSpinner hidden={!isLoadingData} />

            <div className="mt-5" hidden={isLoadingData}>
                <CodeDataGrid
                    sort={{ columnIndex: 0, direction: "asc", columnProperty: "id" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Frotas",
                        subtitle: "Listagem de frotas",
                        filter: true
                    }}
                    actions={[
                        { label: 'Inserir', style: 'colored', onClick: inserir },
                        { label: 'Editar', style: 'flat', disabled: !selected, onClick: () => consultar(false) },
                        { label: 'Excluir', style: 'destructive', disabled: !selected, onClick: () => setShowPopupDelete(true) },
                        { label: 'Consultar', style: 'flat', disabled: !selected, onClick: () => consultar(true) }
                    ]}
                    columns={dataTableColumns}
                    onRowSelect={(item) => setSelected(item.selected ? item : undefined)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />

                <ClientesFrotasCadastroPage
                    show={modalState.show}
                    id={modalState.id}
                    state={modalState.state}
                    onClose={onModalClose}
                    onSave={onModalClose}
                />

                <Popup
                    isShowing={showPopupDelete}
                    message="Confirma a exclusão do veículo selecionado?"
                    isDestructive={true}
                    onConfirm={excluir}
                    onCancel={() => setShowPopupDelete(false)}
                />
            </div>
        </>
    );
}