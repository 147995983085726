import { Button, Textarea, TextInput } from "flowbite-react";
import { FC } from "react";
import { CodeLabel } from "../../../components/CodeLabel";
import { CodePesquisa } from "../../../components/CodePesquisa";
import { Groupbox } from "../../../components/Groupbox";
import { MaskedInput } from "../../../components/MaskedInput";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { FormStateType, UserType } from "../../../enums";
import { useClienteColumns } from "../../../models/cliente/useClienteColumns";
import { useClienteVeiculoColumns } from "../../../models/cliente_veiculo/useClienteVeiculoColumns";
import { useModalTabDados } from "./ModalTabDados.hook";
import { CotacaoModel, getCotacaoStatus, getCotacaoStatusColor } from "../../../models/cotacao/CotacaoModel";
import { Badge } from "../../../components/Badge";
import { useSessionContext } from "../../../contexts/SessionContext";

export const ModalTabDados: FC<ModalFormTabProps<CotacaoModel>> = (props) => {
    const { model, setModel, onSearchCliente, onSearchVeiculo, handleImprimir } = useModalTabDados(props);
    const readOnly = props.state === FormStateType.view;
    const currentSession = useSessionContext();
    const userSession = currentSession.session?.user;

    return (
        <div className="grid grid-cols-12">
            {!model.id || model.id === 0 ?
                <></> :
                <div className="col-span-12 mb-3">
                    <h2 className="ml-auto text-xl font-extrabold text-black/70">{`ID: #${(model.id ?? 0).toString().padStart(5, "0")}`}</h2>
                </div>
            }

            <div className="col-span-4 md:col-span-3 mr-6">
                <CodeLabel value="Data:" htmlFor="inputData" className="mb-1" />
                <TextInput
                    id="inputData"
                    type={"date"}
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={model.dataCadastro}
                    readOnly={true} />
            </div>

            <div className="col-span-4 md:col-span-3 mr-6">
                <CodeLabel value="Validade:" htmlFor="inputValidade" className="mb-1" />
                <TextInput
                    id="inputValidade"
                    type={"date"}
                    disabled={readOnly}
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={model.dataValidade}
                    onChange={(e) => setModel({ ...model, dataValidade: e.currentTarget.value })} />
            </div>

            <div className="col-span-6 md:col-span-3 mt-3 md:mt-0">
                <CodeLabel value="Situação:" htmlFor="selectSituacao" className="mb-1" />
                <div className="py-2.5 flex items-center align-middle">
                    <Badge color={getCotacaoStatusColor(model.status)} value={getCotacaoStatus(model.status)} />
                </div>
            </div>

            <div className="col-span-6 md:col-span-3 mt-3 md:mt-0 flex items-center ml-auto">
                {props.state !== FormStateType.add && <div className={`flex align-middle`}>
                    <div className={`ml-auto`}>
                        <Button size={"sm"} color={"light"} onClick={handleImprimir}>Imprimir <i className="ml-1.5 pi pi-print text-sm" /></Button>
                    </div>
                </div>}
            </div>

            <Groupbox label="Informações do Cliente" id="groupBoxCliente" className="col-span-12 mt-6" hidden={userSession?.tipoUsuario === UserType.customer}>                
                <div className="form-control mt-3 col-span-12 md:col-span-6 md:mr-6">
                    <CodePesquisa
                        id="cpsCliente"
                        valueField={{ label: "Código:", property: "id", value: model.cliente?.id === 0 ? "" : model.cliente?.id }}
                        textField={{ label: "Razão Social:", property: "razaoSocial", value: model.cliente?.razaoSocial }}
                        modal={{
                            title: "Pesquisa de Clientes",
                            dataColumns: useClienteColumns(),
                            sort: { columnProperty: "razaoSocial", direction: "asc", columnIndex: 2 }
                        }}
                        readOnly={readOnly}
                        endpoint="cliente"
                        onSearch={onSearchCliente}
                    />
                </div>

                <div className="form-control mt-3 col-span-12 md:col-span-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteFantasia" value="Fantasia:" />
                    <TextInput
                        id="inputClienteFantasia"
                        type="text"
                        readOnly={true}
                        value={model.cliente?.fantasia ?? ""}
                        maxLength={150}
                    />
                </div>

                <div className="form-control mt-3 col-span-4 md:col-span-3 mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteCnpj" value="CNPJ:" />
                    <MaskedInput
                        id="inputClienteCnpj"
                        mask="##.###.###/####-##"
                        value={model.cliente?.cnpj}
                        readOnly={true}
                    />
                </div>

                <div className="form-control mt-3 col-span-4 md:col-span-3 mr-6">
                    <CodeLabel className="mb-1" htmlFor="inputClienteIE" value="Inscrição Estadual:" />
                    <TextInput
                        id="inputClienteIE"
                        value={model.cliente?.inscricaoEstadual ?? ""}
                        readOnly={true}
                    />
                </div>
            </Groupbox>

            <Groupbox label="Informações do Veículo" id="groupBoxVeiculo" className="col-span-12 mt-6">
                <div className="form-control mt-3 col-span-12 md:col-span-6 mr-6">
                    <CodePesquisa
                        id="cpsVeiculo"
                        valueField={{ label: "Código:", property: "id", value: model.veiculo?.id === 0 ? "" : model.veiculo?.id }}
                        textField={{ label: "Modelo:", property: "modeloMarca", value: model.veiculo?.modeloMarca ?? "" }}
                        modal={{
                            title: "Pesquisa de Veículo",
                            dataColumns: useClienteVeiculoColumns(),
                            sort: { columnProperty: "placa", direction: "asc", columnIndex: 3 }
                        }}
                        endpoint={`cliente/${userSession?.clienteId ?? model.cliente?.id ?? 0}/veiculo`}
                        params={'&inativo=false'}
                        readOnly={readOnly}
                        onSearch={onSearchVeiculo}
                    />
                </div>

                <div className="form-control col-span-6 mr-6 md:col-span-3 mt-3">
                    <CodeLabel value="Renavam:" htmlFor="inputRenavam" className="mb-1" />
                    <TextInput id="inputRenavam" value={model.veiculo?.renavam ?? ""}
                        readOnly={true} />
                </div>

                <div className="form-control col-span-6 md:col-span-3 mt-3">
                    <CodeLabel value="Placa:" htmlFor="inputPlaca" className="mb-1" />
                    <TextInput id="inputPlaca" value={model.veiculo?.placa ?? ""}
                        readOnly={true} />
                </div>
            </Groupbox>

            <Groupbox label="Observações" key={"groupBoxObservacoes"} className="col-span-12 mt-6">
                <div className="col-span-12 mt-3">
                    <Textarea
                        id="inputObservacoes"
                        rows={5}
                        readOnly={readOnly}
                        value={model.observacoes ?? ""}
                        onChange={(e) => setModel({ ...model, observacoes: e.currentTarget.value })} />
                </div>
            </Groupbox>
        </div >
    );
};