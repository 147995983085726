import { CotacaoEncerrarCommand, CotacaoExecutarCommand } from "../commands/CotacaoCommand";
import { CotacaoModel } from "../models/cotacao/CotacaoModel";
import { BaseService } from "./BaseService";
import { CotacaoServiceProps } from "./requests/index.";
import { DefaultResponse } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/cotacao`;

export class CotacaoService extends BaseService {
    public static async get(props: CotacaoServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<CotacaoModel>> {
        let query = `?limit=${props.limit}&page=${props.page}`;
        if (props.credenciadaId) query += `&credenciadaId=${props.credenciadaId}`;
        if (props.clienteId) query += `&clienteId=${props.clienteId}`;
        if (props.dataCadastroIni ?? false) query += `&dataCadastroIni=${props.dataCadastroIni}`;
        if (props.dataCadastroFim ?? false) query += `&dataCadastroFim=${props.dataCadastroFim}`;
        if (props.dataAlteracaoIni ?? false) query += `&dataAlteracaoIni=${props.dataAlteracaoIni}`;
        if (props.dataAlteracaoFim ?? false) query += `&dataInicial=${props.dataAlteracaoFim}`;
        props.status?.forEach(element => query += `&status=${element as number}`);

        return await this.GET<CotacaoModel>(`${ENDPOINT}${query}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<CotacaoModel>> {
        return await this.GET<CotacaoModel>(`${ENDPOINT}/${id}`);
    }

    public static async insert(data: unknown): Promise<DefaultResponse> {
        return await this.POST(`${ENDPOINT}`, data);
    }

    public static async update(id: number, data: unknown): Promise<DefaultResponse> {
        return await this.PATCH(`${ENDPOINT}/${id}`, data);
    }

    public static async aprovarCotacao(id: number, credenciadaId: number, itens: number[]): Promise<DefaultResponse> {
        return await this.POST(`${ENDPOINT}/aprovar`, {
            id,
            credenciadaId,
            itemId: itens
        });
    }

    public static async executarCotacao(command: CotacaoExecutarCommand): Promise<DefaultResponse> {
        return await this.POST(`${ENDPOINT}/${command.cotacaoId}/executar`, command);
    }

    public static async encerrarCotacao(command: CotacaoEncerrarCommand, notaFiscal: File): Promise<DefaultResponse> {
        var formData = new FormData();
        formData.append("notaFiscal", notaFiscal);
        formData.append("registroSaida", JSON.stringify(command));
        return await this.POSTFORM(`${ENDPOINT}/${command.cotacaoId}/encerrar`, formData);
    }

    public static async delete(id: number): Promise<DefaultResponse> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}