import { FC, useRef } from "react";
import { ModalFormTabProps } from "../../../components/ModalForm/types";
import { Popup } from "../../../components/Popup";
import { useModalTabItens } from "./ModalTabItens.hook";
import { ModalTabItensLancamento } from "./ModalTabItensLancamento";
import { CotacaoModel } from "../../../models/cotacao/CotacaoModel";
import styled from "styled-components";
import { ContextMenu } from 'primereact/contextmenu';
import { ModelState } from "../../../enums";
import { StaticListItemPecasServicos } from "./StaticListItemPecasServicos";
import CodeStaticList from "../../../components/CodeStaticList";
import CodeStaticListItem from "../../../components/CodeStaticList/Item";
import { Button } from "primereact/button";

export const ModalTabItens: FC<ModalFormTabProps<CotacaoModel>> = (props) => {
    const contextMenu = useRef<ContextMenu>(null);
    const {
        item, setItem, setItemIndex, readonly,
        modalState, handleModalClose, handleModalSave,
        handleButtonDelete, handleButtonEditar, handleButtonInserir,
        model, showPopupDelete, handleButtonClosePopupDelete, handleButtonExcluirPopupDelete
    } = useModalTabItens(props);


    return (
        <StyledContainer onContextMenu={(e) => contextMenu.current?.show(e)}>
            <div className="flex flex-col">
                <div className="grid grid-cols-12 m-3">
                    <div className="col-span-12">
                        {model.itens?.filter(_ => _.state !== ModelState.deleted).length > 0 ?
                            <></> :
                            <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                                <span className="text-slate-500">Nenhum item lançado para esta cotação</span>
                            </div>}


                        <CodeStaticList>
                            {model.itens?.filter(_ => _.state !== ModelState.deleted).map((_item, idx) =>
                                <CodeStaticListItem key={idx}>
                                    <StaticListItemPecasServicos model={_item} key={idx} state={props.state} index={idx}
                                        onVisualizar={() => { setItemIndex(idx); handleButtonEditar(_item); }}
                                        onExcluir={() => { setItemIndex(idx); handleButtonDelete(); }} />
                                </CodeStaticListItem>)}
                        </CodeStaticList>
                    </div>

                    <ModalTabItensLancamento
                        item={[item, setItem]}
                        show={modalState.show}
                        state={modalState.state}
                        onClose={handleModalClose}
                        onSave={handleModalSave}
                    />

                    <Popup
                        isShowing={showPopupDelete}
                        message="Confirma a exclusão do item selecionado?"
                        isDestructive={true}
                        onConfirm={handleButtonExcluirPopupDelete}
                        onCancel={handleButtonClosePopupDelete}
                    />


                    <Button icon="pi pi-plus" visible={!readonly} onClick={handleButtonInserir} rounded aria-label="Filter" style={{ position: "absolute", right: 30, bottom: 90 }} />
                </div>
            </div>
        </StyledContainer >
    );
}

const StyledContainer = styled.div`
    min-height: 26rem;
    margin: -1.5rem;
`