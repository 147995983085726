import { UserType } from "../../enums";
import CodeUtil from "../../utils/CodeUtil";
import { BaseModel } from "../base";

export interface UsuarioModel extends BaseModel {
    nome: string;
    nomeUsuario: string;
    sobrenome?: string;
    nomeCompleto?: string;
    email: string;
    foto?: string;
    tipoUsuario: UserType;
    isBloqueado: boolean;
    token?: string;
    senhaAtual?: string;
    novaSenha?: string;
    novaSenhaConfirmacao?: string;
    credenciadaId?: number;
    clienteId?: number;
    empresaCredenciada?: string
}

export const defaultUsuario: UsuarioModel = {
    nome: '',
    email: '',
    nomeUsuario: '',
    tipoUsuario: UserType.customer,
    isBloqueado: false,
    isSelected: true,
}

export const validateUsuario = (model: UsuarioModel): string[] => {
    let result = [] as string[];

    if (CodeUtil.isNullOrEmpty(model.nome)) {
        result.push("O campo Nome deve ser preenchido.");
    }

    if (CodeUtil.isNullOrEmpty(model.nomeUsuario)) {
        result.push("O campo Nome de Usuário deve ser preenchido.");
    }

    if (CodeUtil.isNullOrEmpty(model.email)) {
        result.push("O campo E-mail deve ser preenchido.");
    }

    if (!CodeUtil.isNullOrEmpty(model.email) && !CodeUtil.isValidEmail(model.email)) {
        result.push("O campo E-mail não possui um e-mail válido.");
    }

    return result;
}