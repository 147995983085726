import { FC } from "react";
import { useCatalogosPage } from "./index.hook";
import CodeSpinner from "../../components/CodeSpinner";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { useCatalogoColumns } from "../../models/catalogo/CatalogoColumnsHook";
import { Popup } from "../../components/Popup";
import { CatalogosCadastroPage } from "../catalogos_cadastro";
import { DocumentViewer } from "../../components/DocumentViewer";
import { CatalogoModel } from "../../models/catalogo/CatalogoModel";
import { Button } from "flowbite-react";

export const CatalogosPage: FC = () => {
    const { isLoadingData, dataSource, setShowPopupDelete, showPopupDelete,
        selectedRow: selected, setSelectedRow: setSelected,
        pagination, setPage, modalState, onModalClose,
        inserir, consultar, excluir, docViewerState, setDocViewerState, visualizarCatalogo
    } = useCatalogosPage();

    return (
        <>
            <CodeSpinner hidden={!isLoadingData} />

            <div className="mt-5" hidden={isLoadingData}>
                <CodeDataGrid
                    sort={{ columnIndex: 1, direction: "asc", columnProperty: "nome" }}
                    datasource={dataSource}
                    striped={true}
                    header={{
                        title: "Catálogos",
                        subtitle: "Listagem de catálogos",
                        filter: true
                    }}
                    actions={[
                        { label: 'Inserir', style: 'colored', onClick: inserir },
                        { label: 'Editar', style: 'flat', disabled: !selected, onClick: () => consultar(false) },
                        { label: 'Excluir', style: 'destructive', disabled: !selected, onClick: () => setShowPopupDelete(true) },
                        { label: 'Consultar', style: 'flat', disabled: !selected, onClick: () => consultar(true) }
                    ]}
                    columns={[
                        ...useCatalogoColumns(),
                        {
                            property: "url", title: " ", onFormatValue: (item: CatalogoModel) => (!!!item.url ? <></> :
                                <Button style={{
                                    background: "transparent !important"
                                }} 
                                size={"xs"}
                                color={"light"}
                                onClick={async () => await visualizarCatalogo(item)} 
                                >Visualizar</Button>)
                        },
                    ]}
                    onRowSelect={(item) => setSelected(item.selected ? item : undefined)}
                    pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
                />

                <CatalogosCadastroPage
                    show={modalState.show}
                    id={modalState.id}
                    state={modalState.state}
                    onClose={onModalClose}
                    onSave={onModalClose}
                />

                <Popup
                    isShowing={showPopupDelete}
                    message="Confirma a exclusão do catálogo selecionado?"
                    isDestructive={true}
                    onConfirm={excluir}
                    onCancel={() => setShowPopupDelete(false)}
                />
            </div>

            {docViewerState.show &&
                <DocumentViewer
                    title={docViewerState.title}
                    uri={docViewerState.url}
                    onClose={() => setDocViewerState({ url: "", show: false })} />
            }
        </>
    );
};