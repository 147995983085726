import { FC } from "react";
import styled from "styled-components";
import { Tabs } from "flowbite-react";
import { CotacoesPanel } from "../../components/CotacoesPanel";
import { OrdemServicoPanel } from "../../components/OrdemServicoPanel";

export const MainPage: FC = () => {
    return (
        <StyledDashboard className="flex w-full">
            <StyledCotacoesPanel className="rounded-md shadow">

                {/* eslint-disable-next-line */}
                <Tabs.Group aria-label="Abas" style="underline">
                    <Tabs.Item title="Cotações" active={true}>
                        <CotacoesPanel />
                    </Tabs.Item>

                    <Tabs.Item title="Ordens de Serviço">
                        <OrdemServicoPanel />
                    </Tabs.Item>
                </Tabs.Group>
            </StyledCotacoesPanel>
        </StyledDashboard>
    );
};

const StyledDashboard = styled.div`
    padding: 2rem;
`
const StyledCotacoesPanel = styled.div`
    background-color: #FFFFFF;
    width: 100%;
`;


