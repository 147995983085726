import { Tabs } from "flowbite-react";
import { FC } from "react";
import { ModalTabDados } from "./components/ModalTabDados";
import { ModalTabItens } from "./components/ModalTabItens";
import { ModalFormProps } from "../../components/ModalForm/types";
import { useCotacaoCadastroPage } from "./index.hook";
import ModalForm from "../../components/ModalForm";
import { ModalTabCredenciadas } from "./components/ModalTabCredenciadas";
import { ModalTabCatalogos } from "./components/ModalTabCatalogos";
import { FormStateType, UserType } from "../../enums";

export const CotacoesCadastroPage: FC<ModalFormProps> = (props) => {
    const { 
        onFormClose, onFormSave, getFormProps, model, setModel, cliente, userSession, readonly
     } = useCotacaoCadastroPage(props);

    return (
        <ModalForm
            title={props.title ?? "Lançamento de Cotação"}
            errors={[]}
            show={props.show}
            state={props.state}
            isSaving={getFormProps().saving}
            size="7xl"
            onClose={onFormClose}
            onSave={onFormSave}
        >
            {readonly && 
            <div className="flex align-middle justify-center -mx-1.5 p-3 bg-red-600 text-white">
                <span className="text-center text-md font-medium">A cotação já possui credenciadas vinculadas permitindo apenas a adição de novas credenciadas, sem possibilidade de edição.</span>
            </div>}

            {/* eslint-disable-next-line */}
            <Tabs.Group aria-label="Abas" style="underline">
                <Tabs.Item title="Dados" active={true} key="os">
                    <ModalTabDados
                        model={[model, setModel]}
                        show={props.show}
                        state={readonly ? FormStateType.view : props.state}
                        id={props.id}
                        key="id"
                    />
                </Tabs.Item>

                <Tabs.Item title="Peças e Serviços" key="itens">
                    <ModalTabItens
                        model={[model, setModel]}
                        show={props.show}
                        state={readonly ? FormStateType.view : props.state}
                        id={props.id}
                        key="id"
                    />
                </Tabs.Item>

                <Tabs.Item title="Catálogos" key="catalogos" disabled={!(cliente?.permitirVisualizarCatalogos ?? true)}>
                    <ModalTabCatalogos
                        model={[model, setModel]}
                        show={props.show}
                        state={props.state}
                        id={props.id}
                        key="id"
                    />
                </Tabs.Item>

                <Tabs.Item disabled={userSession?.tipoUsuario === UserType.dealer} title="Credenciadas" key="credenciadas">
                    <ModalTabCredenciadas
                        model={[model, setModel]}
                        show={props.show}
                        state={props.state}
                        id={props.id}
                        key="id"
                    />
                </Tabs.Item>
            </Tabs.Group>
        </ModalForm>
    );
};
