import { Tabs } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultCliente, ClienteModel, validateCliente } from "../../../../models/cliente/ClienteModel";
import { ClienteService } from "../../../../services/ClienteService";
import { UNKOWN_EXCEPTION } from "../../../../utils/Constants";
import { ClientesTabContatos } from "./TabContatos";
import { ClientesTabDados } from "./TabDados";
import { ClientesTabVeiculos } from "./TabVeiculos";
import { ClientesTabCondutores } from "./TabCondutores";

export const ClientesModal: FC<ModalFormProps> = (props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [model, setModel] = useState<ClienteModel>(defaultCliente);

  const onFormClose = () => {
    setErrors([]);
    setFormLoaded(false);
    props.onClose?.call(this);
  };

  const onFormLoad = useCallback(async () => {
    if (!props.show || formLoaded) return;

    if (props.id !== null && props.id !== undefined && props.id > 0) {
      const response = await ClienteService.getId(props.id);
      if (!response.success) {
        ShowToast({ message: response.messages });
        return;
      }

      setModel(response.data[0]);
    } else setModel(defaultCliente);

    setFormLoaded(true);
  }, [props.id, props.show, formLoaded, setFormLoaded]);

  const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    const validationResult = validateCliente(model);
    if (validationResult.length > 0) {
      ShowToast({ message: validationResult });
      return;
    }

    try {
      setSaving(true);
      const response = props.state === FormStateType.add ? await ClienteService.add(model) : await ClienteService.update(model);

      if (!response.success) {
        console.log(model);
        ShowToast({ message: response.messages });
        return;
      }

      props.onSave?.call(this, e);
    } catch (error) {
      console.log(error);
      ShowToast({ message: UNKOWN_EXCEPTION });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => { onFormLoad(); });

  if (!props.show) return <></>;

  return (
    <ModalForm
      title={props.title ?? "Cadastro de Clientes"}
      errors={errors}
      show={props.show}
      state={props.state}
      isSaving={saving}
      size="5xl"
      onClose={onFormClose}
      onSave={onFormSave}
    >
      {/* eslint-disable-next-line */}
      <Tabs.Group aria-label="Abas" style="underline">
        <Tabs.Item title="Cliente" active={true} key="cliente">
          <ClientesTabDados {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>

        <Tabs.Item title="Frota" key="frota">
          <ClientesTabVeiculos {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>
        <Tabs.Item title="Contatos" key="contatos">
          <ClientesTabContatos {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>

        <Tabs.Item title="Condutores" key="condutores">
          <ClientesTabCondutores {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>
      </Tabs.Group>
    </ModalForm>
  );
};
