import { UserType } from "../enums";
import { UsuarioModel } from '../models/usuario';
import { UsuarioProfilePicture } from "../models/usuario_profile_picture";
import { SessionManager } from "../utils/SessionManager";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${BaseService.BASE_URL}/usuario`;

export class UsuarioService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<UsuarioModel>> {
        let params = "";
        if (SessionManager.getSession().user?.tipoUsuario !== UserType.admin)
            params += `&nivelPermissao=${SessionManager.getSession().user?.tipoUsuario}`;

        if (SessionManager.getSession().user?.tipoUsuario === UserType.customer)
            params += `&clienteId=${SessionManager.getSession().user?.clienteId ?? 0}`;

        if (SessionManager.getSession().user?.tipoUsuario === UserType.dealer)
            params += `&credenciadaId=${SessionManager.getSession().user?.credenciadaId ?? 0}`;

        return await this.GET<UsuarioModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}${params}`);
    }

    public static async getId(id: number): Promise<DefaultResponse<UsuarioModel>> {
        return await this.GET<UsuarioModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.POST<UsuarioModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.PATCH<UsuarioModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async updateProfile(id: number, data: unknown): Promise<DefaultResponse<UsuarioModel>> {
        return await this.PATCH(`${ENDPOINT}/${id}/perfil`, data);
    }

    public static async updateProfilePicture(id: number, profilePicture: File): Promise<DefaultResponse<UsuarioProfilePicture>> {
        var formData = new FormData();
        formData.append("profilePicture", profilePicture);
        return await this.POSTFORM<UsuarioProfilePicture>(`${ENDPOINT}/${id}/perfil/foto`, formData);
    }

    public static async delete(id: number): Promise<DefaultResponse<UsuarioModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}