import { Badge } from "flowbite-react";
import React, { useCallback, useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi/index";
import { IoMdClose } from "react-icons/io/index";
import { UsuarioModel } from "../../models/usuario";
import { FormStateType } from "../../enums";
import { ShowToast } from "../../components/CodeToast";
import CodeSpinner from "../../components/CodeSpinner";
import { Popup } from "../../components/Popup";
import { UsuarioService } from "../../services/UsuarioService";
import { UsuariosModal } from "./components/Modal";
import ProfileImage from "../../images/profile.png";
import { ModalProps } from "../../types";
import { KeyboardEvent } from "react";
import { CodeDataGrid } from "../../components/CodeDataGrid";
import { ICodeDataGridPagination, ICodeDataGridRow } from "../../components/CodeDataGrid/types";

const UsuariosPage: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<UsuarioModel[]>([]);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Omit<ICodeDataGridPagination, 'onPageChanged'>>({ page: 1, pageCount: 0, limit: 100, recordCount: 0 });
  const [selectedModel, setSelectedModel] = useState<UsuarioModel | null>(null);
  const [selectedRow, setSelectedRow] = useState<ICodeDataGridRow>();

  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalProps>({
    show: false,
    state: FormStateType.view,
    id: null,
  });

  const onFormLoad = useCallback(async () => {
    setIsLoadingData(true);
    UsuarioService.get({ page: page, limit: 100 })
      .then((response) => {
        setDataSource(response.data ?? []);

        setPagination({
          page: response.pagination.page,
          pageCount: response.pagination.pageCount,
          limit: response.pagination.limit,
          recordCount: response.pagination.recordCount
        });

      })
      .finally(() => setIsLoadingData(false));
  }, [setDataSource, setIsLoadingData, setPagination, page]);

  useEffect(() => {
    onFormLoad();
  }, [onFormLoad]);

  const add = () =>
    setModalState({
      ...modalState,
      state: FormStateType.add,
      show: true,
      id: 0,
    });

  const viewOrEdit = (edit: boolean) => {
    if (selectedModel?.id === undefined) {
      ShowToast({
        message: `Nenhum registro selecionado para ${edit ? "edição" : "visualização"
          }`,
      });
      return;
    }

    setModalState({
      state: edit ? FormStateType.edit : FormStateType.view,
      show: true,
      id: selectedModel.id,
    });
  };

  const deleteItem = async () => {
    try {
      let response = await UsuarioService.delete(selectedModel!.id!);
      if (!response.success) {
        console.log(response.messages);
        return;
      }

      await onFormLoad();
    } catch (error) {
      console.log(error);
    } finally {
      setShowPopupDelete(false);
    }
  };

  const handleKeydownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code.toLowerCase() === "escape") {
      setModalState({ ...modalState, show: false, id: null });
      return;
    }
  }

  return (
    <div onKeyDown={handleKeydownEvent}>
      <CodeSpinner hidden={!isLoadingData} fullPage={true} />

      <div className="mt-5" hidden={isLoadingData}>
        <CodeDataGrid
          sort={{ columnIndex: 2, direction: "asc", columnProperty: "nomeCompleto" }}
          datasource={dataSource}
          striped={true}
          header={{
            title: "Usuários",
            subtitle: "Listagem de usuários ativos e inativos",
            filter: true
          }}
          actions={[
            { label: 'Inserir', style: 'colored', onClick: add },
            { label: 'Editar', style: 'flat', onClick() { viewOrEdit(true) }, disabled: selectedRow === undefined },
            { label: 'Excluir', style: 'destructive', onClick() { setShowPopupDelete(true) }, disabled: selectedRow === undefined },
            { label: 'Consultar', style: 'flat', onClick() { viewOrEdit(false) }, disabled: selectedRow === undefined },
          ]}
          columns={[
            { property: "id", title: "Código", alignment: "center", onFormatValue(value: UsuarioModel) { return <span>{(value.id as Number).toString().padStart(3, "0")}</span> } },
            {
              property: "isBloqueado", title: "Ativo", alignment: "center", onFormatValue(value: UsuarioModel) {
                return (
                  <div className="flex justify-center flex-wrap items-center gap-2">
                    {(value.isBloqueado) ? (
                      <Badge size="sm" color="failure" icon={IoMdClose} />
                    ) : (
                      <Badge icon={HiCheck} size="sm" color="success" />
                    )}
                  </div>
                );
              },
            },
            {
              property: "nomeCompleto", title: "Nome", onFormatValue(value: UsuarioModel) {
                return (
                  <div className="flex">
                    <img
                      className="w-6 h-6 rounded-full"
                      src={value.foto ?? ProfileImage}
                      alt=""
                    />
                    <div className="pl-2 pt-0.5">{value.nomeCompleto}</div>
                  </div>
                );
              },
            },
            { property: "email", title: "E-mail" },
            { property: "nomeUsuario", title: "Login" },
            { property: "tipoUsuario", title: "Nível de Acesso", alignment: "center" },
          ]}
          onRowSelect={(item) => { setSelectedModel(item.selected ? item.item : null); setSelectedRow(item.selected ? item : undefined); }}
          pagination={{ ...pagination, onPageChanged: (page) => setPage(page) }}
        />

        <UsuariosModal
          show={modalState.show}
          id={modalState.id}
          state={modalState.state}
          title="Cadastro de Usuários"
          onClose={() => {
            setModalState({ ...modalState, show: false, id: null });
          }}
          onSave={async () => {
            setModalState({ ...modalState, show: false, id: null });
            await onFormLoad();
          }}
        />

        <Popup
          isShowing={showPopupDelete}
          message="Confirma a exclusão do registro selecionado?"
          isDestructive={true}
          onConfirm={async () => await deleteItem()}
          onCancel={() => setShowPopupDelete(false)}
        />
      </div>
    </div>
  );
};

export default UsuariosPage;
